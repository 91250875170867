import React, { useEffect, useState } from "react";
import "./styles/FloatSelected.css";
import useCart from "../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const FloatSelected = ({ setShowLogin, setRedirect }) => {
  const [price, setPrice] = useState(0);
  const { selectedCourse, setSelectedCategory } = useCart();
  const [name, setName] = useState(Cookies.get("user_name"));
  useEffect(() => {
    setName(Cookies.get("user_name"));
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedCourse?.customPack?.length !== 0) {
      let newPrice;
      if (selectedCourse?.customPack?.length < 4) {
        newPrice = selectedCourse?.customPack?.length * 699;
      } else {
        newPrice = selectedCourse?.customPack?.length * 375 - 1;
      }
      if (newPrice) setPrice(Math.min(newPrice, 3999));
      else setPrice(0);
    } else {
      setPrice(0);
    }
  }, [selectedCourse?.customPack]);
  return (
    <div className="float-s-cont">
      <div className="float-s-inner-cont">
        <div className="flc-course-cont">
          {selectedCourse?.customPack?.length || 0}
          <span>Selected Courses</span>
        </div>
        <div className="flc-price-cont">
          ₹ {price} <span>Total Price</span>
        </div>
        {selectedCourse?.customPack?.length > 0 ? (
          <div
            className="flc-cta pointer"
            onClick={() => {
              setSelectedCategory("");
              if (!name) {
                if (selectedCourse?.customPack?.length > 0) {
                  setRedirect("/packpayment");
                  setShowLogin(true);
                } else {
                  setRedirect(null);
                  window.location.href = "#choose";
                }
              } else {
                if (selectedCourse?.customPack?.length > 0) {
                  navigate(`/packpayment`);
                } else {
                  window.location.href = "#choose";
                }
              }
            }}
          >
            Proceed
          </div>
        ) : (
          <div
            className="flc-cta pointer"
            onClick={() => (window.location.href = "#choose")}
          >
            Add Course
          </div>
        )}
      </div>
    </div>
  );
};

export default FloatSelected;
