import React, { useEffect, useState } from "react";
import "../../styles/roadmap.css";
import upload from "../../assets/upload.svg";
import Curriculum from "../../../Components/Curriculum";
import tracks from "../../../contents/Contents.json";
import { useNavigate } from "react-router-dom";
import useCart from "../../../contexts/Cart";
import Cookies from "js-cookie";

function Roadmap({ track, price, notPurchasedCourses, setShowLogin }) {
  const [subSlug, setSubSlug] = useState("");
  const [modal, setModal] = useState(false);

  const [modalData, setModalData] = useState({});
  const { setSelectedCourse, setSelectedCategory } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    if (subSlug && track?.curric) {
      const data = track?.curric?.find((item) => item.slug === subSlug);
      setModalData(data);
    }
  }, [subSlug]);

  return (
    <>
      {modal && (
        <div className="curr-modal">
          <div className="curr-modal1">
            <div class="curriculum-name disFlex">
              <p>
                Curriculum: <br />
                {modalData?.name}
              </p>
              <span
                class="step-close"
                onClick={() => {
                  document.body.classList.remove("noScroll");
                  setModal(false);
                }}
              >
                ×
              </span>
            </div>

            <Curriculum
              slug={modalData?.slug}
              hideDetails={true}
              topics1={tracks[modalData?.slug]?.topics1}
              topics2={tracks[modalData?.slug]?.topics2}
              details={tracks[modalData?.slug]?.details}
            />
          </div>
        </div>
      )}
      <section className="upg-fs-section upg-fs-section-roadmap">
        <div className="upg-fs-roadmap">
          <div className="upg-fs-roadmap-heading upg-fs-f-48 upg-fs-fw-500 upg-fs-text-center">
            <p>Roadmap to</p>
            <p className="upg-fs-fw-700"> {track?.heroHead}</p>
          </div>

          <div className="upg-fs-roadmap-card-top">
            <div className="upg-fs-main">
              {track?.curric?.map((item, key) => {
                return (
                  <div
                    className={`upg-fs-main-card ${
                      item?.purchased && "purchased"
                    }`}
                    key={key}
                  >
                    <div
                      className={`upg-fs-main-card-no ${
                        item?.purchased && "purchased"
                      }`}
                    >{`0${key + 1}`}</div>
                    <div className="upg-fs-mc-top">
                      <div className="upg-fs-mct-top">
                        <img src={item?.src} alt="" />
                      </div>
                      <div className="upg-fs-mct-bottom">
                        <div
                          className={`upg-fs-mct-b-head ${
                            item?.purchased && "purchased"
                          }`}
                        >
                          {item?.name}
                        </div>
                        <div
                          className={`upg-fs-mct-b-dur ${
                            item?.purchased && "purchased"
                          }`}
                        >
                          {item?.purchased ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M6.83203 2.42578H18.832V8.42578L14.832 12.4258L18.832 16.4258V22.4258H6.83203V16.4258L10.832 12.4258L6.83203 8.42578V2.42578ZM16.832 16.9258L12.832 12.9258L8.83203 16.9258V20.4258H16.832V16.9258ZM12.832 11.9258L16.832 7.92578V4.42578H8.83203V7.92578L12.832 11.9258ZM10.832 6.42578H14.832V7.17578L12.832 9.17578L10.832 7.17578V6.42578Z"
                                fill="white"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M6.5 2.42578H18.5V8.42578L14.5 12.4258L18.5 16.4258V22.4258H6.5V16.4258L10.5 12.4258L6.5 8.42578V2.42578ZM16.5 16.9258L12.5 12.9258L8.5 16.9258V20.4258H16.5V16.9258ZM12.5 11.9258L16.5 7.92578V4.42578H8.5V7.92578L12.5 11.9258ZM10.5 6.42578H14.5V7.17578L12.5 9.17578L10.5 7.17578V6.42578Z"
                                fill="#800080"
                              />
                            </svg>
                          )}
                          <span>{item?.duration}</span>
                        </div>
                      </div>
                    </div>
                    <div className="upg-fs-mc-bottom">
                      {item?.purchased ? (
                        <div className="upg-fs-purchased">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="12"
                            viewBox="0 0 22 12"
                            fill="none"
                          >
                            <path
                              d="M1.45703 7.05078L5.20703 10.8008M10.457 4.80078L14.207 1.05078M7.45703 7.05078L11.207 10.8008L20.207 1.05078"
                              stroke="#29BF12"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span>Already purchased</span>
                        </div>
                      ) : (
                        <div
                          className="upg-fs-npurchased pointer"
                          onClick={() => {
                            document.body.classList.add("noScroll");
                            setSubSlug(item?.slug);

                            setModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_844_2423)">
                              <path
                                d="M13.4948 5.80078C8.07812 5.80078 3.45229 9.16995 1.57812 13.9258C3.45229 18.6816 8.07812 22.0508 13.4948 22.0508C18.9115 22.0508 23.5373 18.6816 25.4115 13.9258C23.5373 9.16995 18.9115 5.80078 13.4948 5.80078ZM13.4948 19.3424C10.5048 19.3424 8.07812 16.9158 8.07812 13.9258C8.07812 10.9358 10.5048 8.50911 13.4948 8.50911C16.4848 8.50911 18.9115 10.9358 18.9115 13.9258C18.9115 16.9158 16.4848 19.3424 13.4948 19.3424ZM13.4948 10.6758C11.6965 10.6758 10.2448 12.1274 10.2448 13.9258C10.2448 15.7241 11.6965 17.1758 13.4948 17.1758C15.2931 17.1758 16.7448 15.7241 16.7448 13.9258C16.7448 12.1274 15.2931 10.6758 13.4948 10.6758Z"
                                fill="#800080"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_844_2423">
                                <rect
                                  width="26"
                                  height="26"
                                  fill="white"
                                  transform="translate(0.5 0.925781)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>View Curriculum </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="upg-fs-roadmap-card-bottom">
            <div className="upg-fs-rcb-card">
              <div className="upg-fs-rcb-top">
                <div className="upg-fs-rcb-top-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="22"
                    viewBox="0 0 37 22"
                    fill="none"
                  >
                    <path
                      d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                      fill="url(#paint0_linear_23_1145)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_23_1145"
                        x1="0"
                        y1="11"
                        x2="37"
                        y2="11"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.0391675" stopColor="#800080" />
                        <stop offset="0.527799" stopColor="#B466C1" />
                        <stop offset="0.92416" stopColor="#800080" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="upg-fs-rcb-top-heading">Real Projects</div>
              </div>
              <div className="upg-fs-rcb-bottom">
                Get ready to work on the creative projects, designed to craft
                your skills learned, and build a strong portfolio
              </div>
            </div>
            <div className="upg-fs-rcb-card">
              <div className="upg-fs-rcb-top">
                <div className="upg-fs-rcb-top-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="22"
                    viewBox="0 0 37 22"
                    fill="none"
                  >
                    <path
                      d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                      fill="url(#paint0_linear_23_1145)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_23_1145"
                        x1="0"
                        y1="11"
                        x2="37"
                        y2="11"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.0391675" stopColor="#800080" />
                        <stop offset="0.527799" stopColor="#B466C1" />
                        <stop offset="0.92416" stopColor="#800080" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="upg-fs-rcb-top-heading">Resume Building</div>
              </div>
              <div className="upg-fs-rcb-bottom">
                Get ready to flaunt your skills and crack any Web based Full
                Stack Development interview
              </div>
            </div>
            <div className="upg-fs-rcb-card">
              <div className="upg-fs-rcb-top">
                <div className="upg-fs-rcb-top-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="22"
                    viewBox="0 0 37 22"
                    fill="none"
                  >
                    <path
                      d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                      fill="url(#paint0_linear_23_1145)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_23_1145"
                        x1="0"
                        y1="11"
                        x2="37"
                        y2="11"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.0391675" stopColor="#800080" />
                        <stop offset="0.527799" stopColor="#B466C1" />
                        <stop offset="0.92416" stopColor="#800080" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="upg-fs-rcb-top-heading">
                  Assured Industry Internship
                </div>
              </div>
              <div className="upg-fs-rcb-bottom">
                Get guranteed one month internship with minimum stipend of
                ₹5ooo/month on completing the course along with alll the
                assigmnets & projects
              </div>
            </div>
          </div>

          <div className="upg-fs-roadmap-pricing">
            <div className="upg-fs-roadmap-pricing-top">
              <div className="upg-fs-rpt-pri">₹{price}</div>
              <div className="upg-fs-rpt-dis">₹2,999</div>
              <div className="upg-fs-rpt-off">
                {(((2999 - price) * 100) / 2999).toFixed(0)}%
              </div>
            </div>
            <div
              className="upg-fs-roadmap-pricing-bottom"
              onClick={() => {
                setSelectedCategory(`upgrade-pro-intern#${price}`);

                setSelectedCourse((prev) => ({
                  ...prev,
                  upgrade: notPurchasedCourses,
                }));

                if (!Cookies.get("user_email")) {
                  setShowLogin(true);
                } else {
                  if (notPurchasedCourses?.length > 0) {
                    navigate("/packpayment");
                  } else {
                    alert("Already purchased all courses");
                    window.location.href =
                      "https://upskill.tutedude.com/dashboard";
                  }
                }
              }}
            >
              <img src={upload} alt="Upload" />
              <span>Upgrade to Pro Pack</span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Roadmap;
