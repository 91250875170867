import { useRef, useState } from "react";
import "./VerficationCode.css";
import Cookies from "js-cookie";
const VerficationCode = ({ className = "", setDetails, setOtpp }) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [password, setPassword] = useState("");
  const [cnfPass, setCnfPass] = useState("");
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      return;
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  const handleVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !otp.join("").length > 0 ||
      !password.length > 0 ||
      !cnfPass.length > 0
    ) {
      alert("Please enter all details");
      setLoading(false);
      return;
    }

    if (password !== cnfPass) {
      alert("Passwords do not match");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: Cookies.get("email"),
      password,
      otp: otp.join(""),
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch("https://api.tutedude.com/lms/api/user/reset", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          alert("Password reset Successfull!!!");
          Cookies.set("user_email", result?.data?.user?.email);
          Cookies.set("user_name", result?.data?.user?.name || "Welcome");
          Cookies.remove("email");
          Cookies.remove("otpsent");
          window.location.reload();
        }
      })
      .catch((error) => alert(error.message));
    setLoading(false);
  };
  return (
    <form
      onSubmit={handleVerify}
      className={`verify-verfication-code ${className}`}
    >
      <div
        className="verify-akar-iconscross-wrapper"
        onClick={() => {
          setOtpp(false);
          setDetails(true);
        }}
      >
        <svg
          className="akar-iconscross verify-akar-iconscross"
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector"
            d="M10 10L1 1M10 1L1 10"
            stroke="white"
            stroke-width="1.79221"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <section className="verify-verfication-code-inner">
        <div className="verify-frame-parent">
          <div className="verify-verify-account-wrapper">
            <b className="verify-verify-account">{`Verify Account `}</b>
          </div>
          <div className="verify-enter-verfication-codeotp">
            Enter Verfication Code(OTP) sent to your Whatsapp
          </div>
        </div>
      </section>
      <div className="verify-name-input-fields-wrapper">
        <div className="verify-name-input-fields">
          {[...Array(4)].map((_, index) => (
            <input
              key={index}
              type="text"
              autoComplete="one-time-code"
              maxLength="1"
              className="verify-name-field"
              onChange={(e) => handleInputChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
            />
          ))}
        </div>
      </div>
      <section className="np-name-field-parent">
        <div className="np-name-field">
          <input
            autoComplete="one-time-code"
            className="np-password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="np-name-field1">
          <input
            autoComplete="one-time-code"
            className="np-confirm-password"
            placeholder="Confirm Password"
            type="text"
            value={cnfPass}
            onChange={(e) => setCnfPass(e.target.value)}
          />
        </div>
      </section>
      <section className="verify-verfication-code-child">
        <div className="verify-frame-group">
          <div className="verify-if-you-didnt-receive-a-code-wrapper">
            <div className="verify-if-you-didnt-container">
              <span className="verify-if-you-didnt">
                If you didn’t receive a code !
              </span>
              <b className="verify-resend"> Resend</b>
            </div>
          </div>
          <button
            type="submit"
            className="verify-verify-wrapper"
            disabled={loading}
          >
            <b className="verify-verify">{loading ? "Resetting" : "Verify"} </b>
          </button>
        </div>
      </section>
    </form>
  );
};

export default VerficationCode;
