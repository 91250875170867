import React from "react";
import "../../styles/benefits.css";
import Ben1 from "../../assets/benefit1.png";
import Ben2 from "../../assets/benefit2.png";
import Ben3 from "../../assets/benefit3.png";
import useCart from "../../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Benefits({ setShowLogin, notPurchasedCourses, price }) {
  const { setSelectedCourse, setSelectedCategory } = useCart();
  const navigate = useNavigate();

  return (
    <section className="alac-pack-section alac-pack-benefits">
      <h2 className="alac-pack-f-48 alac-pack-fw-400 alac-pack-text-black alac-pack-text-center">
        Explore{" "}
        <span className="alac-pack-fw-700 alac-pack-text-pink">
          {" "}
          Exclusive benefits
        </span>
      </h2>
      <div className="alac-pack-benefits-main">
        <div className="alac-pack-benefits-top">
          <div className="alac-pack-benefit-card alac-pack-benefit-card-1">
            <div className="alac-pack-benefit-card-icon">
              <img src={Ben1} alt="Learn At Your Own Pace" />
            </div>
            <div className="alac-pack-benefit-card-bottom">
              <p className="alac-pack-benefit-card-heading">
                {" "}
                Learn At Your Own Pace
              </p>
              <p className="alac-pack-benefit-card-text">
                Study when it suits you, Mastering courses at your speed for
                effective learning
              </p>
            </div>
          </div>
          <div className="alac-pack-benefit-card alac-pack-benefit-card-2">
            <div className="alac-pack-benefit-card-icon">
              <img src={Ben2} alt="Refund Upon Completion" />
            </div>
            <div className="alac-pack-benefit-card-bottom">
              <p className="alac-pack-benefit-card-heading">
                Refund Upon Completion
              </p>
              <p className="alac-pack-benefit-card-text">
                get Refund of Rs.175 for every course you complete.Complete all
                26 courses for full refund
              </p>
            </div>
          </div>
          <div className="alac-pack-benefit-card alac-pack-benefit-card-3">
            <div className="alac-pack-benefit-card-icon">
              <img src={Ben3} alt="Lifetime Opportunity " />
            </div>
            <div className="alac-pack-benefit-card-bottom">
              <p className="alac-pack-benefit-card-heading">
                Lifetime Opportunity{" "}
              </p>
              <p className="alac-pack-benefit-card-text">
                No Rush! this offer is for a lifetime, so enhance your skills
                worry free
              </p>
            </div>
          </div>
        </div>
        <div className="alac-pack-benefits-bottom">
          <div className="alac-pack-benefits-bottom-left">
            <p>Don't Miss Out!</p>
            <p>Enroll Now To Unlock A World Of Knowledge!</p>
          </div>
          <button
            className="alac-pack-benefits-bottom-right"
            onClick={() => {
              setSelectedCategory(`upgrade-pro-allaccess#${price}`);

              setSelectedCourse((prev) => ({
                ...prev,
                upgrade: notPurchasedCourses,
              }));
              if (!Cookies.get("user_email")) {
                setShowLogin(true);
              } else {
                if (notPurchasedCourses?.length > 0) {
                  navigate("/packpayment");
                } else {
                  alert("Already purchased all courses");
window.location.href = "https://upskill.tutedude.com/dashboard";
                }
              }
            }}
          >
            Enroll Now
          </button>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
