import React, { useEffect, useRef, useState } from "react";
// import Hero from "../Components/Hero";
import Curriculum from "../Components/Curriculum";
import Certificate from "../Components/Certificate";
import Experiences from "../Components/Experiences";
import IntroFaq from "../Components/IntroFaq";
import IntroFooter from "../Components/IntroFooter";
import Cookies from "js-cookie";
import Login from "../Components/Login";
import SignUp from "../Components/SignUp";
import { useNavigate, useParams } from "react-router-dom";

import contents from "../contents/Contents.json";
import { Helmet } from "react-helmet";
import useCart from "../contexts/Cart";
import CourseFeatures from "../Components/CourseFeatures";
import WhyChooseUs from "../Components/WhyChooseUs";
import OtherCourses from "../Components/OtherCourses";
import Layout from "../ProPacks/pages/Layout";
import ProPackCards from "../Components/HomePage/Components/ProPackCards";
import ForgotPass from "../Components/ForgotPass";
import DoubtSolve from "../Components/Reusable/DoubtSolve";
import IframeVideo from "../Components/IframeVideo";
import VideoCarousel from "../Components/VideoCarousel";
import ResumeBuilding from "../Components/Reusable/ResumeBuilding";
import WhyBest from "../Components/StickyCards/WhyBest";
// import HowScholarship from "../Components/StickyCards/HowScholarship";
import OldHero from "../Components/OldHero";
import { DiwaliStrip } from "../Components/DiwaliStrip/DiwaliStrip";
import DiwaliHowScholarship from "../Components/StickyCards/DiwaliHowRefund/DiwaliHowScholarship";
// import Refund from "../Components/Refund";
import Duration from "../NewSections/Duration/Duration";
import Backoffer from "../NewSections/BackOffer";

const Category = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const name = Cookies.get("user_name");
  const { slug } = useParams();
  const [showForgot, setShowForgot] = useState(false);

  const [bought, setBought] = useState(false);
  const navigate = useNavigate();
  const { setSelectedCategory } = useCart();
  const newUI = [
    "uiux",
    "mernstack",
    "datascience",
    "financialmodelling",
    "machinelearning",
    "powerbi",
    "dataanalytics",
  ];

  const isNew = newUI.includes(slug);
  const ids = [
    "datascientist",
    "techgeek",
    "fullstackdev",
    "business",
    "designing",
    "appdev",
  ];

  if (!ids.includes(slug) && !Object.keys(contents).includes(slug)) {
    Cookies.remove("slug");
    alert("wrong URL!!!");
    navigate("/");
  }

  const customSlideToExploreRef = useRef(null);
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [redirect, setRedirect] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "INR",
        value: 699.0,
        items: [
          {
            item_id: slug,
            item_name: contents[slug]?.title,
            price: 699.0,
          },
        ],
      },
    });
  }, [slug]);

  useEffect(() => {
    const email = Cookies.get("user_email");

    try {
      if (email) {
        fetch("https://api.tutedude.com/dashboard?student_id=" + email, {
          method: "GET",
        })
          .then((res) => res.json())
          .then((data) => {
            Cookies.set(
              "bought",
              JSON.stringify(data?.dashboard?.courses || []),
              {
                domain: process.env.REACT_APP_DOMAIN,
              }
            );
            if (data?.dashboard?.courses?.includes(slug)) {
              setBought(true);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const boughtCookies = Cookies.get("bought");

    var bt = false;

    if (boughtCookies !== undefined) {
      try {
        bt = JSON.parse(boughtCookies).includes(slug);
      } catch (error) {
        console.error("Error parsing JSON from cookies:", error);
      }
      setBought(bt);
    }
  }, [slug]);

  const pro = [
    "datascientist",
    "techgeek",
    "fullstackdev",
    "business",
    "designing",
    "appdev",
  ];

  const breadcrumbJsonLd = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://tutedude.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Course Intro",
        item: `https://tutedude.com/category`,
      },
      {
        "@type": "ListItem",
        position: 3,
        name: contents[slug]?.title,
        item: `https://tutedude.com/category/${slug}`,
      },
    ],
  };

  if (pro.includes(slug)) {
    return (
      <Layout
        setShowSign={setShowSign}
        redirect={redirect}
        setShowLogin={setShowLogin}
        slug={slug}
        showLogin={showLogin}
        showSign={showSign}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{contents[slug]?.title}</title>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJsonLd)}
        </script>
        <link rel="canonical" href={`https://tutedude.com/category/${slug}`} data-react-helmet="true"/>
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}

      {showLogin && (
        <Login
          setBought={setBought}
          slug={slug}
          redirect={redirect}
          setShowLogin={setShowLogin}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      {showFloatSelected && (
        <div className="mobile-floater">
          {bought ? (
            <div
              className="mobile-floater-inner"
              onClick={() =>
                window.open("https://upskill.tutedude.com/dashboard")
              }
            >
              Go to Dashboard
            </div>
          ) : (
            <div
              className="mobile-floater-inner"
              onClick={() => {
                setSelectedCategory(slug);
                if (!name) {
                  setRedirect(`/payment`);
                  setShowSign(true);
                } else {
                  navigate(`/payment`);
                }
              }}
            >
              Enroll Now
            </div>
          )}
        </div>
      )}
      {showFloatSelected && <DiwaliStrip />}
      {/* <div className="access-chips">
          <div
            className="access-chips-card"
            onClick={() => navigate("/#courses")}
          >
            Explore Other courses
          </div>
          <div
            className="access-chips-card"
            onClick={() => (window.location.href = "#combo")}
          >
            Combo Packs
          </div>
        </div> */}
      <OldHero
        setShowLogin={setShowLogin}
        setShowSign={setShowSign}
        bought={bought}
        setRedirect={setRedirect}
        cont={contents[slug]}
        slug={slug}
        newMOb={isNew}
      />

      {width <= 500 && <DiwaliHowScholarship />}
      {width > 500 && <CourseFeatures introStats={contents[slug].introStats} />}
      {width > 500 && <Backoffer price="699" deadline="3" />}
      <Duration />
      {width <= 500 && <WhyBest />}

      <div ref={customSlideToExploreRef}>
        <IframeVideo cont={contents[slug]} />
      </div>

      <Curriculum
        bought={bought}
        setShowLogin={setShowSign}
        slug={slug}
        setRedirect={setRedirect}
        topics1={contents[slug].topics1}
        topics2={contents[slug].topics2}
        details={contents[slug].details}
      />
      {/* {width <= 500 && <EnrollBefore />} */}
      <Certificate name={contents[slug].name} />
      <DoubtSolve />
      <ResumeBuilding />
      {width > 500 && <WhyChooseUs />}
      <VideoCarousel slug={slug} />
      <ProPackCards slug={slug} />
      <OtherCourses hideHeader={true} />

      <Experiences />
      <IntroFaq />
      <IntroFooter />
    </>
  );
};

export default Category;
