
import React, { useEffect, useState } from "react";
import "./Styles/OtherCourses.css";
// import useCart from "../contexts/Cart";
// import { useNavigate } from "react-router-dom";
// import courses from "../contents/Courses.json";
import Cookies from "js-cookie";

const OtherCourses = ({ hideHeader, hide, upg, setRedirect, setShowLogin }) => {
  // const { setSelectedCategory, setSelectedCourse } = useCart();
  // const navigate = useNavigate();
  const [name, setName] = useState(Cookies.get("user_name"));
  useEffect(() => {
    setName(Cookies.get("user_name"));
  }, []);
  return (
    <div className="ocs-cont">
      {!hideHeader && !upg && (
        <div className="headings">
          Interested In Our <br />
          <span className="headings-col">Other Courses Too ? </span>
        </div>
      )}
      {!hideHeader && upg && (
        <div className="headings">
          Interested In <br />
          <span className="headings-col">All Other Courses? </span>
        </div>
      )}
      <div className="ocs-inner-cont ">
        <div className={`nocs-card ${hide === 1 && "nocs-card-hide"}`}>
          <div className="nocs-card-top">
            <div className="nocs-ct-l">
              Customized course <br />
              Pack
              <div className="nocs-ctl-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.09243 1.667H12.9092C13.1029 1.66695 13.2514 1.66691 13.3813 1.67961C14.3044 1.76992 15.0601 2.32497 15.3805 3.07264H4.62109C4.94152 2.32497 5.69715 1.76992 6.62034 1.67961C6.75017 1.66691 6.8987 1.66695 7.09243 1.667Z"
                    fill="#992E9D"
                  />
                  <path
                    d="M5.25964 3.93555C4.10079 3.93555 3.15057 4.63534 2.83346 5.5637C2.82685 5.58305 2.82051 5.60251 2.81445 5.62206C3.14624 5.52158 3.49155 5.45594 3.8411 5.41113C4.74142 5.29571 5.8792 5.29576 7.20089 5.29584H12.9443C14.266 5.29576 15.4038 5.29571 16.3041 5.41113C16.6536 5.45594 16.999 5.52158 17.3307 5.62206C17.3247 5.60251 17.3184 5.58305 17.3117 5.5637C16.9946 4.63534 16.0444 3.93555 14.8855 3.93555H5.25964Z"
                    fill="#992E9D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7723 6.28516H7.22634C4.414 6.28516 3.00782 6.28516 2.21795 7.10751C1.42807 7.92987 1.61392 9.20037 1.98561 11.7415L2.33808 14.151C2.62957 16.1438 2.77531 17.1401 3.52297 17.7368C4.27062 18.3335 5.37335 18.3335 7.5788 18.3335H12.4198C14.6253 18.3335 15.7281 18.3335 16.4758 17.7368C17.2234 17.1401 17.3691 16.1438 17.6606 14.151L18.0131 11.7415C18.3848 9.20046 18.5706 7.92986 17.7808 7.10751C16.9908 6.28516 15.5847 6.28516 12.7723 6.28516ZM12.1503 13.162C12.6157 12.8735 12.6157 12.1267 12.1503 11.8383L9.34068 10.0965C8.88843 9.81612 8.33268 10.181 8.33268 10.7584V14.2419C8.33268 14.8192 8.88843 15.1841 9.34068 14.9038L12.1503 13.162Z"
                    fill="#992E9D"
                  />
                </svg>
                Choose Any <b>4 or 4+ Courses</b>
              </div>
            </div>
            <div className="nocs-ctr">New</div>
          </div>

          <div className="nocs-card-mid">Avail 100% REFUND in 1 year</div>
          <div className="nocs-card-btm">
            <div className="nocs-cb-l">
              <div className="nocs-cb-ltt">
                ₹1,499 <span>₹2,796</span>
              </div>
              <div className="nocs-cb-ltb">You get 45% off</div>
            </div>
            <div
              className="nocs-cb-r"
              onClick={() =>
                (window.location.href = `${window.location.origin}/category/customizepack`)
              }
            >
              Know More
            </div>
          </div>
        </div>
        <div className={`nocs-card ${hide === 2 && "nocs-card-hide"}`}>
          <div className="nocs-card-top">
            <div className="nocs-ct-l">
              All Access <br />
              course Pack
              <div className="nocs-ctl-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.09243 1.667H12.9092C13.1029 1.66695 13.2514 1.66691 13.3813 1.67961C14.3044 1.76992 15.0601 2.32497 15.3805 3.07264H4.62109C4.94152 2.32497 5.69715 1.76992 6.62034 1.67961C6.75017 1.66691 6.8987 1.66695 7.09243 1.667Z"
                    fill="#992E9D"
                  />
                  <path
                    d="M5.25964 3.93555C4.10079 3.93555 3.15057 4.63534 2.83346 5.5637C2.82685 5.58305 2.82051 5.60251 2.81445 5.62206C3.14624 5.52158 3.49155 5.45594 3.8411 5.41113C4.74142 5.29571 5.8792 5.29576 7.20089 5.29584H12.9443C14.266 5.29576 15.4038 5.29571 16.3041 5.41113C16.6536 5.45594 16.999 5.52158 17.3307 5.62206C17.3247 5.60251 17.3184 5.58305 17.3117 5.5637C16.9946 4.63534 16.0444 3.93555 14.8855 3.93555H5.25964Z"
                    fill="#992E9D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7723 6.28516H7.22634C4.414 6.28516 3.00782 6.28516 2.21795 7.10751C1.42807 7.92987 1.61392 9.20037 1.98561 11.7415L2.33808 14.151C2.62957 16.1438 2.77531 17.1401 3.52297 17.7368C4.27062 18.3335 5.37335 18.3335 7.5788 18.3335H12.4198C14.6253 18.3335 15.7281 18.3335 16.4758 17.7368C17.2234 17.1401 17.3691 16.1438 17.6606 14.151L18.0131 11.7415C18.3848 9.20046 18.5706 7.92986 17.7808 7.10751C16.9908 6.28516 15.5847 6.28516 12.7723 6.28516ZM12.1503 13.162C12.6157 12.8735 12.6157 12.1267 12.1503 11.8383L9.34068 10.0965C8.88843 9.81612 8.33268 10.181 8.33268 10.7584V14.2419C8.33268 14.8192 8.88843 15.1841 9.34068 14.9038L12.1503 13.162Z"
                    fill="#992E9D"
                  />
                </svg>
                Choose <b>all the Courses</b>
              </div>
            </div>
            <div className="nocs-ctr ctr-pop">Popular</div>
          </div>

          <div className="nocs-card-mid">Avail 100% REFUND in Lifetime</div>
          <div className="nocs-card-btm">
            <div className="nocs-cb-l">
              {upg ? (
                <div className="nocs-cb-ltt">
                  ₹3,799 <span>₹ 16,077</span>
                </div>
              ) : (
                <div className="nocs-cb-ltt">
                  ₹4,499 <span>₹ 14,679</span>
                </div>
              )}
              <div className="nocs-cb-ltb">You get 45% off</div>
            </div>
            <div
              className="nocs-cb-r"
              onClick={() => {
                if (upg) {
                  window.location.href = `${window.location.origin}/upgrade/allaccess`;
                } else {
                  window.location.href = `${window.location.origin}/category/allaccess`;
                }
              }}
            >
              Know More
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherCourses;
