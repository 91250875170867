import React, { useEffect, useRef, useState } from "react";
import "./layout.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Hero from "./home/Hero";
import Guarantee from "./home/Guarantee";
import OneGuarantee from "./home/OneGuarantee";
import Highlights from "./home/Highlights";
import Internship from "./home/Internship";
import Roadmap from "./home/Roadmap";
// import Skill from "./home/Skill";
// import Testimonials from "./home/Testimonials";
import Experiences from "../../Components/Experiences";
import Best from "./home/Best";
import Faq from "./home/Faq";
import Footer from "./home/Footer";
// import VideoReview from "../../Components/HomePage/Components/VideoReview";
import "../App.css";
import "../global.css";
import Cookies from "js-cookie";
import tracks from "../../contents/Tracks";
import useCart from "../../contexts/Cart";
import { useNavigate, useParams } from "react-router-dom";
import SignUp from "../../Components/SignUp";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import { Helmet } from "react-helmet";
import VideoCarousel from "../../Components/VideoCarousel";
function Layout(props) {
  const [wid, setWid] = useState(window.innerWidth);

  const { slug } = useParams();
  const { setSelectedCourse } = useCart();
  const name = Cookies.get("user_name");
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const customSlideToExploreRef = useRef(null);
  const [showFloatSelected, setShowFloatSelected] = useState(false);

  const ids = [
    "fullstackdevelopment",
    "pythondevelopment",
    "appdevelopment",
    "datascience",
    "uiuxtrack",
  ];
  const breadcrumbJsonLd = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://tutedude.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: tracks[slug]?.title,
        item: `https://tutedude.com/tracks/${slug}`,
      },
    ],
  };

  window.addEventListener("resize", (e) => {
    if (window.innerWidth < 880 && wid >= 880) {
      setWid(window.innerWidth);
    } else if (window.innerWidth >= 880 && wid < 880) {
      setWid(window.innerWidth);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "INR",
        value: 2999.0,
        items: [
          {
            item_id: "internship" + slug,
            item_name: tracks[slug]?.title,
            price: 2999.0,
          },
        ],
      },
    });
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });
  }, []);
  if (!ids.includes(slug)) navigate("/");
  return (
    <div className="int-track-layout">
      <Helmet>
        <title>{tracks[slug]?.title}</title>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJsonLd)}
        </script>
        <link rel="canonical" href={`https://tutedude.com/tracks/${slug}`} data-react-helmet="true"/>
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          redirect={redirect}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      {showFloatSelected && (
        <div className="mobile-floater">
          <div
            className="mobile-floater-inner"
            onClick={() => {
              if (!name) {
                setSelectedCourse((prev) => ({
                  ...prev,
                  tracks: [tracks[slug].curric],
                }));
                setRedirect("/trackspayment");
                setShowSign(true);
              } else {
                setSelectedCourse((prev) => ({
                  ...prev,
                  tracks: [tracks[slug].curric],
                }));
                navigate("/trackspayment");
              }
            }}
          >
            {`Enroll Now`}
          </div>
        </div>
      )}
      <Navbar setShowLogin={setShowSign} setShowSign={setShowSign} />
      <Hero
        data={tracks[slug]}
        setRedirect={setRedirect}
        setShowLogin={setShowSign}
      />
      <div ref={customSlideToExploreRef}>
        <Guarantee
          data={tracks[slug]}
          setRedirect={setRedirect}
          setShowLogin={setShowSign}
        />
      </div>
      <OneGuarantee
        price={tracks[slug]?.price}
        head={tracks[slug]?.curricHead}
      />
      <Highlights />
      <Internship />
      <Roadmap data={tracks[slug]} />

      <VideoCarousel slug={slug} />
      <Experiences />
      <Best />
      <Faq />
      <Footer />
    </div>
  );
}

export default Layout;
