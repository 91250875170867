import React from "react";
import "../../styles/applybtn.css";
function ApplyBtn({ classes, data, onClick, price }) {
  return (
    <p className={`int-track-apply-now-btn ${classes}`} onClick={onClick}>
      {data?.text ? data?.text : "Enroll now for ₹"}
      {price || price === 0 ? price : data.price}
    </p>
  );
}
export default ApplyBtn;
