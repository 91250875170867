import React from "react";
import "./backoffer.css";
import O1 from "../Assests/gif1.gif";
import O2 from "../Assests/gif2.gif";
import O3 from "../Assests/gif3.gif";
function Refund({ price, months, all, h1 = null, h2 = null, h3 = null, h4 = null }) {
  return (
    <div className="ref-new-backoffer">
      <div className="ref-new-back-offer-heading">
        <p className="ref-new-f-48 ref-new-fw-400 ref-new-text-white ref-new-text-center">
          <span className="ref-new-fw-700">{h1 ? h1 : "Here's how"}</span>{h2 ? h2 : " you will"}
        </p>
        <p className="ref-new-f-48 ref-new-fw-400 ref-new-text-white ref-new-text-center">
          {h3 ? h3 : "learn"} <span className="ref-new-fw-700">{h4 ? h4 : "for Free"}</span>
        </p>
      </div>
      <div className="ref-new-backoffer-bottom">
        <div className="ref-new-backoffer-left">
          <div className="ref-new-bol-card bol-card1">
            <div className="ref-new-bol-card-left">
              <img src={O1} alt="Enroll" />
            </div>
            <div className="ref-new-bol-card-middle">
              <div className="ref-new-bol-card-heading">Enroll </div>
              <div className="ref-new-bol-card-text">
                Enroll into your favorite Courses by paying <b>₹{price}</b>
              </div>
            </div>
            <div className="ref-new-bol-card-right">1</div>
          </div>
          <div className="ref-new-bol-card bol-card2">
            <div className="ref-new-bol-card-left">
              <img src={O2} alt="Course" />
            </div>
            <div className="ref-new-bol-card-middle">
              <div className="ref-new-bol-card-heading">
                {all ? "Complete any 8 Courses " : "Complete Course "}
              </div>
              <div className="ref-new-bol-card-text">
                With all the lectures and Assignments.
              </div>
            </div>
            <div className="ref-new-bol-card-right">2</div>
          </div>
          <div className="ref-new-bol-card bol-card3">
            <div className="ref-new-bol-card-left">
              <img src={O3} alt="Payment" />
            </div>
            <div className="ref-new-bol-card-middle">
              <div className="ref-new-bol-card-heading">
                100% Refund awarded
              </div>
              <div className="ref-new-bol-card-text">
                Get <b>100% of Fees back</b> in your source bank account.
              </div>
            </div>
            <div className="ref-new-bol-card-right">3</div>
          </div>
        </div>
        <div className="ref-new-backoffer-right">
          <div className="ref-new-backoffer-right-inner">
            <div className="ref-new-bor-top">Duration of course </div>
            <div className="ref-new-bor-bottom">
              <ol>
                <li>
                  Get <b>lifetime course</b> access, Even after getting{" "}
                  <b>100% refund.</b>
                </li>
                <li>
                  For <b>100% refund</b> complete{" "}
                  {all ? "any 8 Courses" : "course"} within{" "}
                  {!all ? <b>{months} months.</b> : <b>Lifetime</b>}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Refund;
