import React, { useEffect, useState } from "react";
import logo from "../Assests/logo.svg";
// import pmtuiux from "../Assests/pmtuiux.svg";
import razorpay from "../Assests/razorpay.svg";
import secure1 from "../Assests/secure1.svg";
import secure2 from "../Assests/secure2.svg";
import "./Payment.css";
import contents from "../contents/Contents.json";
import axios from "axios";
import Cookies from "js-cookie";

import useCart from "../contexts/Cart";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const CustomPayment = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [price, setPrice] = useState("");
  const [courses, setCourses] = useState("");
  const [coursesCart, setCoursesCart] = useState([]);
  const navigate = useNavigate();

  const { selectedCourse, selectedCategory } = useCart();
  useEffect(() => {
    const name = Cookies.get("user_name");
    const email = Cookies.get("user_email");
    let sls = "";
    let pricee = 0;
    if (!name || !email) {
      navigate("/");
    } else {
      setName(name);
      setEmail(email);
    }

    if (selectedCategory === "allAccess") {
      setCoursesCart(selectedCourse?.allAccess[0]);
      setPrice(3999);
      pricee = 3999;
      const slugString = selectedCourse?.allAccess[0]
        ?.map((obj) => obj.slug)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else if (selectedCategory === "combo") {
      setCoursesCart(selectedCourse?.combo[0]);
      setPrice(selectedCourse?.combo[1]);
      pricee = selectedCourse?.combo[1];
      const slugString = selectedCourse?.combo[0]
        ?.map((obj) => obj.url)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else if (selectedCategory === "upgrade") {
      setCoursesCart(selectedCourse?.upgrade);
      let newPrice = 0;
      if (selectedCourse?.upgrade?.length < 3) {
        newPrice = selectedCourse?.upgrade?.length * 699;
      } else if (selectedCourse?.upgrade?.length === 3) {
        newPrice = 799;
      } else {
        newPrice = 799 + (selectedCourse?.upgrade?.length - 3) * 375;
      }
      setPrice(Math.min(newPrice, 3299));
      pricee = Math.min(newPrice, 3299);

      const slugString = selectedCourse?.upgrade
        ?.map((obj) => obj.slug)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else if (selectedCategory.includes("upgrade-pro-pack")) {
      setCoursesCart(selectedCourse?.upgrade);
      setPrice(Number(selectedCategory.split("#")[1]) || 1499);
      pricee = Number(selectedCategory.split("#")[1]) || 1499;
      // console.log(selectedCourse?.upgrade);
      const slugString = selectedCourse?.upgrade
        ?.map((obj) => obj.url)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else if (selectedCategory.includes("upgrade-pro-all")) {
      setCoursesCart(selectedCourse?.upgrade);
      setPrice(Number(selectedCategory.split("#")[1]) || 3999);
      pricee = Number(selectedCategory.split("#")[1]) || 3999;
      const slugString = selectedCourse?.upgrade
        ?.map((obj) => obj.url)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else if (selectedCategory.includes("upgrade-pro-intern")) {
      setCoursesCart(selectedCourse?.upgrade);
      setPrice(Number(selectedCategory.split("#")[1]) || 2999);
      pricee = Number(selectedCategory.split("#")[1]) || 2999;
      const slugString = selectedCourse?.upgrade
        ?.map((obj) => obj.url)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    } else {
      setCoursesCart(selectedCourse?.customPack);
      if (selectedCourse?.customPack?.length !== 0) {
        let newPrice;
        if (selectedCourse?.customPack?.length < 4) {
          newPrice = selectedCourse?.customPack?.length * 699;
        } else {
          newPrice = selectedCourse?.customPack?.length * 375 - 1;
        }
        setPrice(Math.min(newPrice, 3999));
        pricee = Math.min(newPrice, 3999);
      } else {
        setPrice(0);
        pricee = 0;
      }
      const slugString = selectedCourse?.customPack
        ?.map((obj) => obj.slug)
        .join(",");
      setCourses(slugString);
      sls = slugString;
    }

    fetch(
      `https://api.tutedude.com/lms/api/user/saveLeads?email=${email}&course=${sls}`,
      {
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    window.fbq("track", "AddToCart");
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/LjBwCMWls7oDENrLntMC",
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "INR",
        value: pricee,
        items: [
          {
            item_id: "packpayment",
            item_name: sls,
            price: pricee,
          },
        ],
      },
    });
  }, [selectedCourse]);

  const [completed, setCompleted] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("FEESBACK");
  const [applied, setApplied] = useState(true);

  var coupons = {
    OFF200: 200,
    DevTest: 1498,
    ANUDEEPA50: 300,
    NAVNEETA50: 300,
    MANTRA30: 200,
    GENERIC30: 300,
    HTA30: 300,
    EXCEL200: 200,
    SKILL100: 100,
    PREP200: 200,
    INFOHOOP: 200,
    HACK200: 200,
    TD100: 100,
    DUDE30: 200,
    Dude30: 200,
    dude30: 200,
    DUDE300: 300,
    JEET200: 200,
    SRMKZILLA: 300,
    DIWALI300: 300,
    GKARMY: 100,
  };
  const feesback = ["3MONTHSREFUND", "3MONTHREFUND", "FEESBACK", "LEARNIT"];

  const handleInputChange = (e) => {
    setApplied(false);
    setDiscount(0);
    setCoupon(e.target.value.trim());
  };
  const handleApply = () => {
    if (
      feesback.findIndex(
        (fees) => fees.toLocaleLowerCase() === coupon.trim().toLocaleLowerCase()
      ) !== -1
    ) {
      if (selectedCategory === "allAccess") {
        alert(
          "Congrats! You are eligible to get 100% feesback on completing this course. Feesback validity Lifetime :)"
        );
      } else {
        alert(
          "Congrats! You are eligible to get 100% feesback on completing this course within 1 year :)"
        );
      }
      setDiscount(0);
      setApplied(true);
    } else if (coupons[coupon.toLocaleUpperCase()]) {
      setDiscount(coupons[coupon.toLocaleUpperCase()]);
      setApplied(true);
    } else if (!coupons[coupon.toLocaleUpperCase()]) {
      try {
        fetch("https://api.tutedude.com/verify?coupon=" + coupon, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setDiscount(200);
              setApplied(true);
            } else {
              alert("Please Enter a Valid Coupon");
              setDiscount(0);
            }
          });
      } catch (err) {
        alert("Please Enter a Valid Coupon");
        setDiscount(0);
      }
    }
  };

  const checkoutHandler = async (amount, name, email, courses, coupon) => {
    console.log(courses);
    const {
      data: { key },
    } = await axios.get("https://api.tutedude.com/lms/API/getkey");

    const {
      data: { order },
    } = await axios.post("https://api.tutedude.com/lms/API/checkout", {
      amount,
      name,
      email,
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "TuteDude",
      description: `TuteDude ${
        price === 3999 ? "All Access Pack" : "Custom Pack"
      } Purchase`,
      image: logo,
      order_id: order.id,
      receipt: email,

      prefill: {
        name,
        email,
        contact: Cookies.get("user_phone") || "",
      },
      handler: function (response) {
        navigate(
          `/letskillit?amt=${amount}&pid=${response.razorpay_payment_id}&slugs=${courses}`
        );
      },
      notes: {
        customer_name: name,
        customer_email: email,
        course: courses.slice(0, 200),
        rem_course: courses.length > 200 ? courses.slice(200) : "",
        coupon: coupon,
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <>
      <Helmet>
        <title>Razorpay Payment</title>
        <meta property="og:url" content={`${window.location.href}`} />
      </Helmet>
      <div className="pmt-cont">
        <div className="pmt-nav">
          <div className="pmt-logo-cont">
            <img src={logo} alt="Logo" />
          </div>
          {/* <div className="pmt-stepper-cont ">
            <div
              className={`pmt-steps ${onit === 1 ? "onit" : ""} ${
                completed.includes(1) ? "completed" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  className="stroke"
                  cx="12"
                  cy="12"
                  r="11"
                  fill="white"
                  stroke="#d1d1d1"
                  strokeWidth="2"
                />
                <circle cx="12" cy="12" r="7" fill="#d1d1d1" className="fill" />
              </svg>
              <span>Your Cart </span>
            </div>
            <div
              className={`stepper-dots ${
                completed.includes(1) ? "done-bar" : ""
              }`}
            ></div>
            <div
              className={`pmt-steps ${onit === 2 ? "onit" : ""} ${
                completed.includes(2) ? "completed" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  className="stroke"
                  cx="12"
                  cy="12"
                  r="11"
                  fill="white"
                  stroke="#d1d1d1"
                  strokeWidth="2"
                />
                <circle cx="12" cy="12" r="7" fill="#d1d1d1" className="fill" />
              </svg>
              <span>Payment</span>
            </div>
            <div
              className={`stepper-dots ${
                completed.includes(2) ? "done-bar" : ""
              }`}
            ></div>

            <div
              className={`pmt-steps ${onit === 3 ? "onit" : ""} ${
                completed.includes(3) ? "completed" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <circle
                  className="stroke"
                  cx="12"
                  cy="12"
                  r="11"
                  fill="white"
                  stroke="#d1d1d1"
                  strokeWidth="2"
                />
                <circle cx="12" cy="12" r="7" fill="#d1d1d1" className="fill" />
              </svg>
              <span>Confirmed</span>
            </div>
          </div> */}
        </div>
        <div className="pmt-inner-cont">
          <div className="pmt-left-cont">
            <div className="pmt-left-card-cont">
              <div className="pmt-left-head">Purchase summary </div>
              {/* <div className="pmt-summary"> */}
              <div className="pmt-left-head1">
                {coursesCart?.length} course(s) selected
              </div>

              <div className="pmt-cards-cont">
                {coursesCart &&
                  coursesCart?.map((course, ind) => (
                    <div className="pmt-summary-card" key={ind}>
                      <div className="pmt-course-head">
                        {contents[course.slug]?.name || course?.title}
                      </div>
                      <div className="pmt-course-text">Rs. 699</div>
                    </div>
                  ))}
              </div>
              {/* </div> */}
              <div className="pmt-rzp-footer-cont">
                <img src={razorpay} alt="razorpay" />
                <img src={secure1} alt="secrure1" />
                <img src={secure2} alt="secure2" />
              </div>
            </div>
          </div>
          <div className="pmt-right-cont">
            <div className="pmt-right-card">
              <div className="pmt-card-upper">
                <div className="pmt-applied">
                  100% REFUND OFFER APPLIED
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_787_3015)">
                      <path
                        d="M21.41 11.58L12.41 2.58C12.05 2.22 11.55 2 11 2H4C2.9 2 2 2.9 2 4V11C2 11.55 2.22 12.05 2.59 12.42L11.59 21.42C11.95 21.78 12.45 22 13 22C13.55 22 14.05 21.78 14.41 21.41L21.41 14.41C21.78 14.05 22 13.55 22 13C22 12.45 21.77 11.94 21.41 11.58ZM13 20.01L4 11V4H11V3.99L20 12.99L13 20.01Z"
                        fill="#800080"
                      />
                      <path
                        d="M6.5 8C7.32843 8 8 7.32843 8 6.5C8 5.67157 7.32843 5 6.5 5C5.67157 5 5 5.67157 5 6.5C5 7.32843 5.67157 8 6.5 8Z"
                        fill="#800080"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_787_3015">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="pmt-ltd-text">
                  You are now eligible for getting <b>100%</b> of your
                  enrollment fee back on your course completion.
                </div>
              </div>
              <div className="pmt-card-mid">
                <div className="pmt-total-cont">
                  <span>Total </span>
                  <span>Rs {price}</span>
                </div>
                <div className="pmt-inp-cont">
                  <input
                    type="text"
                    className="pmt-inp"
                    placeholder="Enter coupon code"
                    value={coupon}
                    onChange={handleInputChange}
                  />
                  {!applied && <span onClick={handleApply}>Apply Coupon</span>}
                  {applied && <span type="disabled">Applied</span>}
                </div>
                <div
                  className={`pmt-total-cont2 ${applied ? "showApplied" : ""}`}
                >
                  <span>Coupon discount</span>
                  <span>Rs {discount}</span>
                </div>
                <div className="pmt-total-cont1">
                  <span>To Pay </span>
                  <span>₹ {price - discount}</span>
                </div>
              </div>
              <div
                className="pmt-btn"
                onClick={() => {
                  setCompleted([...completed, 1]);

                  window.fbq("track", " InitiateCheckout");
                  checkoutHandler(
                    price - discount,
                    name,
                    email,
                    courses,
                    coupon
                  );
                }}
              >
                Proceed to Pay
              </div>
            </div>
          </div>
        </div>
        <div className="pmt-rzp-footer-cont-mob">
          <img src={razorpay} alt="razorpay" />
          <img src={secure1} alt="secrure1" />
          <img src={secure2} alt="secure2" />
        </div>
        <div className="pmt-mob-fix-cont">
          <div className="pmt-mob-fix">
            <span>Rs {price - discount}</span>
            <div
              className="pmt-fix-cta"
              onClick={() => {
                setCompleted([...completed, 1]);

                window.fbq("track", " InitiateCheckout");
                checkoutHandler(price - discount, name, email, courses, coupon);
              }}
            >
              proceed to pay
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomPayment;
