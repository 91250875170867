import React from "react";
import "./Styles/CourseFeatures.css";
import cf1 from "../Assests/latest/cf1.png";
import cf2 from "../Assests/latest/cf2.png";
import cf3 from "../Assests/latest/cf3.png";
import cf4 from "../Assests/latest/cf4.png";
import cf5 from "../Assests/latest/cf5.png";
import cf6 from "../Assests/latest/cf6.png";
import cf7 from "../Assests/latest/cf7.png";
import cf8 from "../Assests/latest/cf8.png";
const CourseFeatures = ({ introStats }) => {
  return (
    <div className="cf-cont">
      <div className="headings-col">Course Features</div>
      <div className="cf-inner-cont">
        <div className="cf-chip">
          <img src={cf1} alt="cf1" />
          <div className="cf-det-cont">
            Anyone<span>Can learn (IT/Non IT)</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf2} alt="cf1" />
          <div className="cf-det-cont">
            {introStats?.ratings}
            <span>Course Rating</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf3} alt="cf1" />
          <div className="cf-det-cont">
            {introStats?.learners}
            <span>Learners</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf4} alt="cf1" />
          <div className="cf-det-cont">
            Lifetime<span>Course Access</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf5} alt="cf1" />
          <div className="cf-det-cont">
            1 time <span>Payment</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf6} alt="cf1" />
          <div className="cf-det-cont">
            {introStats?.duration} <span>Content Duration </span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf7} alt="cf1" />
          <div className="cf-det-cont">
            Simple English<span>Language</span>
          </div>
        </div>
        <div className="cf-chip">
          <img src={cf8} alt="cf1" />
          <div className="cf-det-cont">
            Self Paced <span>Recorded lectures</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseFeatures;
