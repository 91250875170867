import React, { useEffect, useState } from "react";
import "./IframeVideo.css";
const IframeVideo = ({ cont }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="iframevideo-cont">
      {windowWidth <= 1020 && (
        <div className="iframe-cont">
          <div className="iframe-vid-cont">
            <iframe
              src={`${cont.video}?rel=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default IframeVideo;
