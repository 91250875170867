import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./styles/Slider.css";
import icard1 from "../../../Assests/latest/icard1.svg";
import icard2 from "../../../Assests/latest/icard2.svg";
import icard3 from "../../../Assests/latest/icard3.svg";
import icard4 from "../../../Assests/latest/icard4.svg";
import icard5 from "../../../Assests/latest/icard5.svg";
// import { useNavigate } from "react-router-dom";
import InternshipCard from "./InternshipCard";
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1201 },
    items: 3,
  },
  tablet1: {
    breakpoint: { max: 1200, min: 1025 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
  },
  smmobile: {
    breakpoint: { max: 463, min: 280 },
    items: 1,
  },
};

const Slider = () => {
  // const navigate = useNavigate();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);
  const tracks = [
    {
      heading: "Full Stack Internship",
      link: "/tracks/fullstackdevelopment",
      img: icard1,
    },
    {
      heading: "Python Internship",
      link: "/tracks/pythondevelopment",
      img: icard3,
    },
    {
      heading: "App Development internship",
      link: "/tracks/appdevelopment",
      img: icard2,
    },
    {
      heading: "Data Science Internship",
      link: "/tracks/datascience",
      img: icard4,
    },
    {
      heading: "UI/UX internship",
      link: "/tracks/uiuxtrack",
      img: icard5,
    },
  ];
  return (
    <div className="parent">
      <Carousel
        responsive={responsive}
        autoPlay={true}
        draggable={true}
        showDots={true}
        infinite={true}
        partialVisible={false}
        keyBoardControl={true}
        centerMode={width <= 1200 && width > 600}
        dotListClass="custom-dot-list-style"
      >
        <InternshipCard data={tracks[0]} />
        <InternshipCard data={tracks[1]} />
        <InternshipCard data={tracks[2]} />
        <InternshipCard data={tracks[3]} />
        <InternshipCard data={tracks[4]} />
      </Carousel>
    </div>
  );
};
export default Slider;
