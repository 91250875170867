import "../styles/Navbar.css";
import logo from "../assets/logo.webp";
function Navbar() {
  return (
    <>
      <div className="tutedude-pages-nav">
        <img
          className="tutedude-pag-logo"
          width="223px"
          height="87px"
          src={logo}
          alt="Logo"
        />
      </div>
    </>
  );
}

export default Navbar;
