import React, { useEffect, useState } from "react";
import "./paymentpage.css";
import Logo from "./assets/logo.png";
import google1 from "../../Assests/Reviews/google1.png";
import Ref100 from "../../Components/StickyCards/DiwaliHowRefund/limitedoffer.png";
// import Ref100 from "../../Assests/Reviews/Scholarship.png";

import useCart from "../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import contents from "../../contents/Contents.json";
import axios from "axios";
import { Helmet } from "react-helmet";

function PaymentPage() {
  const { selectedCategory, setSelectedCategory } = useCart();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [price, setPrice] = useState(699);

  const [couponApply, setCouponApply] = useState(false);
  const [width, setWidth] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState("");

  useEffect(() => {
    if (selectedCategory.length !== 0) {
      Cookies.set("selected", selectedCategory);
    }
    if (Cookies.get("selected")) {
      setSelectedCategory(Cookies.get("selected"));
    }

    const name = Cookies.get("user_name");
    const email = Cookies.get("user_email");
    if (
      !name ||
      !email ||
      (selectedCategory.length === 0 && !Cookies.get("selected"))
    ) {
      navigate("/");
    } else {
      setName(name);
      setEmail(email);

      fetch(
        `https://api.tutedude.com/lms/api/user/saveLeads?email=${email}&course=${selectedCategory}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );

      window.fbq("track", "AddToCart");
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", "AW-711435738");

      gtag("event", "conversion", {
        send_to: "AW-711435738/LjBwCMWls7oDENrLntMC",
      });

      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          currency: "INR",
          value: 699.0,
          items: [
            {
              item_id: "payment",
              item_name: selectedCategory,
              price: 699.0,
            },
          ],
        },
      });
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAndHide = () => {
    if (Object.keys(window.Tawk_API).length > 1 && width <= 700)
      window.Tawk_API.hideWidget();
  };
  useEffect(() => {
    // console.log(window.Tawk_API);
    window.Tawk_API.onLoad = function () {
      // console.log("loaded", window.Tawk_API);
      getAndHide();
    };
    getAndHide();
  }, [width]);

  var coupons = {
    OFF200: 200,
    DevTest: 698,
    ANUDEEPA50: 300,
    NAVNEETA50: 300,
    MANTRA30: 200,
    GENERIC30: 300,
    HTA30: 300,
    EXCEL200: 200,
    SKILL100: 100,
    PREP200: 200,
    INFOHOOP: 200,
    HACK200: 200,
    TD100: 100,
    DUDE30: 200,
    Dude30: 200,
    dude30: 200,
    DUDE300: 300,
    JEET200: 200,
    SRMKZILLA: 300,
    DIWALI300: 300,
    DSSECRETFREESHIV: 698,
    GKARMY: 100,
  };
  const feesback = [
    "3MONTHSREFUND",
    "3MONTHREFUND",
    "FEESBACK",
    "LEARNIT",
    "SCHOLARSHIP",
  ];

  const handleInputChange = (e) => {
    setCouponApply(false);
    setDiscount(0);
    setCoupon(e.target.value.trim());
  };
  const handleApply = () => {
    if (
      feesback.findIndex(
        (fees) => fees.toLocaleLowerCase() === coupon.trim().toLocaleLowerCase()
      ) !== -1
    ) {
      alert(
        "Congrats! You are eligible to get 100% feesback on completing this course within 3 months :)"
      );
      setDiscount(0);
      setCouponApply(true);
    } else if (coupons[coupon.toLocaleUpperCase()]) {
      setDiscount(coupons[coupon.toLocaleUpperCase()]);
      setCouponApply(true);
    } else if (!coupons[coupon.toLocaleUpperCase()]) {
      try {
        fetch("https://api.tutedude.com/verify?coupon=" + coupon, {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              setDiscount(200);
              setCouponApply(true);
            } else {
              alert("Please Enter a Valid Coupon");
              setDiscount(0);
            }
          });
      } catch (err) {
        alert("Please Enter a Valid Coupon");
        setDiscount(0);
      }
    }
  };

  const checkoutHandler = async (amount, name, email, course, coupon) => {
    const {
      data: { key },
    } = await axios.get("https://api.tutedude.com/lms/API/getkey");

    const {
      data: { order },
    } = await axios.post("https://api.tutedude.com/lms/API/checkout", {
      amount,
      name,
      email,
      contact: Cookies.get("user_phone") || "",
    });

    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "TuteDude",
      description: `TuteDude ${contents[selectedCategory]?.name.replace(
        /\//g,
        ""
      )} Course Purchase`,
      image: Logo,
      order_id: order.id,
      receipt: email,
      prefill: {
        name,
        email,
        course,
      },
      handler: function (response) {
        navigate(
          `/letskillit?amt=${amount}&pid=${response.razorpay_payment_id}&slugs=${course}`
        );
      },
      notes: {
        customer_name: name,
        customer_email: email,
        course: course,
        coupon: coupon,
      },
      theme: {
        color: "#121212",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();
  };

  return (
    <>
      <Helmet>
        <title>Razorpay Payment</title>
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>

      <div className="pro-pac-payment-top-outer">
        <nav className="pro-pac-payment-nav">
          <div className="pro-pac-payment-nav-logo">
            <img src={Logo} alt="TuteDude Logo" />
          </div>
          <div className="pro-pac-payment-nav-vertical-line"></div>
          <div className="pro-pac-payment-nav-right">
            An IIT Delhi Alumni Initiative
          </div>
        </nav>
        <main className="pro-pac-payment-main">
          <div className="pro-pac-pm-left">
            <div className="pro-pac-pml-top-outer">
              <div className="pro-pac-pml-top">
                <div className="new-pmt-quote">
                  “Totally worth it!! Best Courses <br />
                  with helpful mentors ”
                </div>
                <div className="pmt-quote-name">
                  -Manish <img src={google1} alt="google1" />
                </div>
              </div>
            </div>

            <div className="pro-pac-pm-middle">
              <div className="new-pmt-summry">Purchase Summary</div>
              <div className="pro-pac-pml-top">
                <div className="new-pmt-course-name">
                  {contents[selectedCategory]?.name} <span>₹699</span>
                </div>
                <div className="new-pmt-sch-text">with 100% Feesback</div>
              </div>

              {couponApply ? (
                <div className="pro-pac-pmrt-coupounapply">
                  <div className="pro-pac-pmrt-coupounapply-left">
                    <p className="pro-pac-pmrtcl-head">
                      {`${coupon} `}
                      <span className="pro-pac-pmrtclh-applied"> Applied</span>
                    </p>
                  </div>
                  <span
                    className="pro-pac-pmrt-coupounapply-right"
                    onClick={() => {
                      setCouponApply(false);
                      setDiscount(0);
                      setCoupon("");
                    }}
                  >
                    - Remove
                  </span>
                </div>
              ) : (
                <div className="pro-pac-pmrt-coupounremove">
                  <input
                    type="text"
                    name="coupoun"
                    value={coupon}
                    placeholder="Enter Coupon Code Here "
                    className="pro-pac-pmrt-coupounremove-input"
                    onChange={handleInputChange}
                  />
                  <div
                    className="pro-pac-pmrt-coupounremove-text"
                    onClick={() => handleApply()}
                  >
                    Apply
                  </div>
                </div>
              )}
            </div>
            <div className="pro-pac-pm-bottom pro-pac-pm-bottom-large">
              <img
                src="https://tutedude.in/feesbackassets/100Safe&secure.png"
                alt="Secure SSL Encryption"
              />
              <img
                src="https://tutedude.in/feesbackassets/SSLEncryption.png"
                alt="Razorpay"
              />
              <img
                src="https://tutedude.in/feesbackassets/Razorpay.png"
                alt="Secure"
              />
            </div>
          </div>
          <div className="pro-pac-pm-right">
            {/* <div className="pro-pac-pmr-top">
              {couponApply ? (
                <div className="pro-pac-pmrt-coupounapply">
                  <div className="pro-pac-pmrt-coupounapply-left">
                    <p className="pro-pac-pmrtcl-head">
                      {`${coupon} `}
                      <span className="pro-pac-pmrtclh-applied"> Applied</span>
                    </p>
                  </div>
                  <span
                    className="pro-pac-pmrt-coupounapply-right"
                    onClick={() => {
                      setCouponApply(false);
                      setDiscount(0);
                      setCoupon("");
                    }}
                  >
                    - Remove
                  </span>
                </div>
              ) : (
                <div className="pro-pac-pmrt-coupounremove">
                  <input
                    type="text"
                    name="coupoun"
                    value={coupon}
                    placeholder="Enter Coupon Code Here "
                    className="pro-pac-pmrt-coupounremove-input"
                    onChange={handleInputChange}
                  />
                  <div
                    className="pro-pac-pmrt-coupounremove-text"
                    onClick={() => handleApply()}
                  >
                    Apply
                  </div>
                </div>
              )}
            </div> */}
            <div className="pro-pac-pmr-bottom">
              <div className="pro-pac-pmrb-top">
                <div className="pro-pac-pmrbt-left">
                  <img src={Ref100} alt="100% Refund" />
                </div>
                <div className="pro-pac-pmrbt-right">
                  <p className="pro-pac-pmrbtr-para1">
                    for limited period only!!
                  </p>
                  <p className="pro-pac-pmrbtr-para2">
                    You are now eligible for getting <b>100%</b> of your
                    enrollment fee back on your course completion.
                  </p>
                </div>
              </div>
              <div className="pro-pac-pmrb-bottom">
                <p className="pro-pac-pmrbb-total">
                  <span>Sub-Total</span>
                  <span>
                    <b>₹{price}</b>
                  </span>
                </p>

                <p className="pro-pac-pmrbb-feedback">
                  <span>FeesBack</span>
                  <span>Applied </span>
                </p>

                {discount > 0 ? (
                  <p className="pro-pac-pmrbb-coupoun">
                    <span>Coupon Discount</span>
                    <span className="pro-pac-pmrbb-coupoun-discount">
                      - Rs {discount}
                    </span>
                  </p>
                ) : null}
                <p className="pro-pac-pmrbb-total">
                  <span>Total</span>
                  <span>
                    <b>₹{price - discount}</b>
                  </span>
                </p>

                <div
                  className="pro-pac-pmrbb-proceed-pay pro-pac-proceed-pay-large"
                  onClick={() => {
                    window.fbq("track", " InitiateCheckout");
                    checkoutHandler(
                      price - discount,
                      name,
                      email,
                      selectedCategory,
                      coupon
                    );
                  }}
                >
                  <span>Proceed to pay</span>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="pro-pac-proceed-pay-outer pro-pac-proceed-pay-small">
          <div
            className="pro-pac-pmrbb-proceed-pay"
            onClick={() => {
              window.fbq("track", " InitiateCheckout");
              checkoutHandler(
                price - discount,
                name,
                email,
                selectedCategory,
                coupon
              );
            }}
          >
            <span>Proceed to pay</span>
            {/* <span className="pro-pac-proceed-vertical"></span>
            <span>
              <b>₹{price - discount}</b>
            </span> */}
          </div>
        </div>
        <div className="pro-pac-pm-bottom pro-pac-pm-bottom-small">
          <img
            src="https://tutedude.in/feesbackassets/100Safe&secure.png"
            alt="Secure SSL Encryption"
          />
          <img
            src="https://tutedude.in/feesbackassets/SSLEncryption.png"
            alt="Razorpay"
          />
          <img
            src="https://tutedude.in/feesbackassets/Razorpay.png"
            alt="Secure"
          />
        </div>
      </div>
    </>
  );
}

export default PaymentPage;
