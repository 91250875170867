import React, { useState } from "react";
import "../../styles/courses.css";
import useCart from "../../../contexts/Cart";

function Courses({ courses }) {
  const { setSelectedCourse, selectedCourse } = useCart();

  const technical = courses.filter((c, i) => c.category === "technical");
  const visual = courses.filter((c, i) => c.category === "visual_arts");
  const business = courses.filter((c, i) => c.category === "bussiness");

  return (
    <section className="ctm-pack-section ctm-pack-course-section" id="choose">
      <h2 className="ctm-pack-f-48 ctm-pack-fw-400 ctm-pack-text-black ctm-pack-text-center">
        Get All These{" "}
        <span className="ctm-pack-fw-700 ctm-pack-text-pink">Your Courses</span>
      </h2>
      <div className="ctm-pack-courses">
        <div className="ctm-pack-course">
          <div className="ctm-pack-course-heading">Technical Courses </div>
          <div className="ctm-pack-course-div-outer">
            <div className="ctm-pack-course-div">
              {technical.map((item, index) => {
                return (
                  <div
                    className={`ctm-pack-course-card-div pointer ${
                      !item.purchased ? "chc-notPurchased1" : "chc-Purchased1"
                    }`}
                    key={index}
                  >
                    <div className="ctm-pack-ccd-left">
                      <div className="ctm-pack-ccdl-left">
                        <img src={item.img} alt={item.text} />
                      </div>
                      <div className="ctm-pack-ccdl-right">
                        {item.course_name}
                      </div>
                    </div>
                    <div className="ctm-pack-ccd-right">
                      {item?.purchased ? (
                        <div>
                          <div className="alreadyPurchased">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="12"
                                viewBox="0 0 22 12"
                                fill="none"
                              >
                                <path
                                  d="M1.45703 7.05078L5.20703 10.8008M10.457 4.80078L14.207 1.05078M7.45703 7.05078L11.207 10.8008L20.207 1.05078"
                                  stroke="#29BF12"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span>Already purchased</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ctm-pack-ccdr-left"
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/category/${item.slug}`,
                              "_blank"
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_181_1234)">
                              <path
                                d="M15.9987 6.5C9.33203 6.5 3.6387 10.6467 1.33203 16.5C3.6387 22.3533 9.33203 26.5 15.9987 26.5C22.6654 26.5 28.3587 22.3533 30.6654 16.5C28.3587 10.6467 22.6654 6.5 15.9987 6.5ZM15.9987 23.1667C12.3187 23.1667 9.33203 20.18 9.33203 16.5C9.33203 12.82 12.3187 9.83333 15.9987 9.83333C19.6787 9.83333 22.6654 12.82 22.6654 16.5C22.6654 20.18 19.6787 23.1667 15.9987 23.1667ZM15.9987 12.5C13.7854 12.5 11.9987 14.2867 11.9987 16.5C11.9987 18.7133 13.7854 20.5 15.9987 20.5C18.212 20.5 19.9987 18.7133 19.9987 16.5C19.9987 14.2867 18.212 12.5 15.9987 12.5Z"
                                fill="#800080"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_181_1234">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="ctm-pack-view">view</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="ctm-pack-course">
          <div className="ctm-pack-course-heading">Creative/Art Course</div>
          <div className="ctm-pack-course-div-outer">
            <div className="ctm-pack-course-div">
              {visual.map((item, index) => {
                return (
                  <div
                    className={`ctm-pack-course-card-div pointer ${
                      !item.purchased ? "chc-notPurchased1" : "chc-Purchased1"
                    }`}
                    key={index}
                  >
                    <div className="ctm-pack-ccd-left">
                      <div className="ctm-pack-ccdl-left">
                        <img src={item.img} alt={item.text} />
                      </div>
                      <div className="ctm-pack-ccdl-right">
                        {item.course_name}
                      </div>
                    </div>
                    <div className="ctm-pack-ccd-right">
                      {item?.purchased ? (
                        <div>
                          <div className="alreadyPurchased">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="12"
                                viewBox="0 0 22 12"
                                fill="none"
                              >
                                <path
                                  d="M1.45703 7.05078L5.20703 10.8008M10.457 4.80078L14.207 1.05078M7.45703 7.05078L11.207 10.8008L20.207 1.05078"
                                  stroke="#29BF12"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span>Already purchased</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ctm-pack-ccdr-left"
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/category/${item.slug}`,
                              "_blank"
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_181_1234)">
                              <path
                                d="M15.9987 6.5C9.33203 6.5 3.6387 10.6467 1.33203 16.5C3.6387 22.3533 9.33203 26.5 15.9987 26.5C22.6654 26.5 28.3587 22.3533 30.6654 16.5C28.3587 10.6467 22.6654 6.5 15.9987 6.5ZM15.9987 23.1667C12.3187 23.1667 9.33203 20.18 9.33203 16.5C9.33203 12.82 12.3187 9.83333 15.9987 9.83333C19.6787 9.83333 22.6654 12.82 22.6654 16.5C22.6654 20.18 19.6787 23.1667 15.9987 23.1667ZM15.9987 12.5C13.7854 12.5 11.9987 14.2867 11.9987 16.5C11.9987 18.7133 13.7854 20.5 15.9987 20.5C18.212 20.5 19.9987 18.7133 19.9987 16.5C19.9987 14.2867 18.212 12.5 15.9987 12.5Z"
                                fill="#800080"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_181_1234">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="ctm-pack-view">view</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="ctm-pack-course">
          <div className="ctm-pack-course-heading">Business Course</div>
          <div className="ctm-pack-course-div-outer">
            <div className="ctm-pack-course-div">
              {business.map((item, index) => {
                const selected = selectedCourse?.customPack?.some(
                  (selected) => selected.course_name === item.course_name
                );
                return (
                  <div
                    className={`ctm-pack-course-card-div pointer ${
                      !item.purchased ? "chc-notPurchased1" : "chc-Purchased1"
                    }`}
                    key={index}
                  >
                    <div className="ctm-pack-ccd-left">
                      <div className="ctm-pack-ccdl-left">
                        <img src={item.img} alt={item.text} />
                      </div>
                      <div className="ctm-pack-ccdl-right">
                        {item.course_name}
                      </div>
                    </div>
                    <div className="ctm-pack-ccd-right">
                      {item?.purchased ? (
                        <div>
                          <div className="alreadyPurchased">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="12"
                                viewBox="0 0 22 12"
                                fill="none"
                              >
                                <path
                                  d="M1.45703 7.05078L5.20703 10.8008M10.457 4.80078L14.207 1.05078M7.45703 7.05078L11.207 10.8008L20.207 1.05078"
                                  stroke="#29BF12"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span>Already purchased</span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ctm-pack-ccdr-left"
                          onClick={() => {
                            window.open(
                              `${window.location.origin}/category/${item.slug}`,
                              "_blank"
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="33"
                            viewBox="0 0 32 33"
                            fill="none"
                          >
                            <g clipPath="url(#clip0_181_1234)">
                              <path
                                d="M15.9987 6.5C9.33203 6.5 3.6387 10.6467 1.33203 16.5C3.6387 22.3533 9.33203 26.5 15.9987 26.5C22.6654 26.5 28.3587 22.3533 30.6654 16.5C28.3587 10.6467 22.6654 6.5 15.9987 6.5ZM15.9987 23.1667C12.3187 23.1667 9.33203 20.18 9.33203 16.5C9.33203 12.82 12.3187 9.83333 15.9987 9.83333C19.6787 9.83333 22.6654 12.82 22.6654 16.5C22.6654 20.18 19.6787 23.1667 15.9987 23.1667ZM15.9987 12.5C13.7854 12.5 11.9987 14.2867 11.9987 16.5C11.9987 18.7133 13.7854 20.5 15.9987 20.5C18.212 20.5 19.9987 18.7133 19.9987 16.5C19.9987 14.2867 18.212 12.5 15.9987 12.5Z"
                                fill="#800080"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_181_1234">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <span className="ctm-pack-view">view</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Courses;
