import React from "react";
import Navbar from "./HomePage/Components/Navbar";
// import Footer from '../InternshipTrack/pages/home/Footer';
import "./Styles/Freelance.css";
import free1 from "../Assests/Freelance/free1.svg";
import free2 from "../Assests/Freelance/free2.svg";
import free3 from "../Assests/Freelance/free3.svg";
import free4 from "../Assests/Freelance/free4.svg";
import free5 from "../Assests/Freelance/free5.svg";
import free6 from "../Assests/Freelance/free6.svg";
import free7 from "../Assests/Freelance/free7.svg";
import free8 from "../Assests/Freelance/free8.svg";
import IntroFooter from "./IntroFooter";
function Freelance() {
  return (
    <div>
      <Navbar />
      <div className="section">
        <div className="freelance-heading f-48 text-center">
          Bring Your Vision to Life with <br />{" "}
          <span className="text-pink fw-700">Our Expert Services</span>
        </div>
        <div className="freelance-main">
          <div className="freelance-cards">
            <div className="freelance-card">
              <div className="freelance-card-top">For Jobs & Internships</div>
              <div className="freelance-card-design">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="538"
                  height="343"
                  viewBox="0 0 538 343"
                  fill="none"
                >
                  <g opacity="0.3">
                    <path
                      d="M458.938 668.481C490.095 632.982 512.723 590.612 525.528 543.79C538.334 496.968 541.068 446.611 533.572 395.594C526.077 344.577 508.501 293.9 481.846 246.455C455.191 199.011 419.98 155.728 378.223 119.079C336.466 82.43 288.981 53.1318 238.48 32.8573C187.978 12.5829 135.449 1.72929 83.8909 0.916199C32.3329 0.103113 -17.2439 9.34648 -62.0089 28.1185C-106.774 46.8906 -145.851 74.8237 -177.008 110.323L140.965 389.402L458.938 668.481Z"
                      fill="url(#paint0_linear_1289_250)"
                      fillOpacity="0.3"
                    />
                    <path
                      d="M450.744 661.29C481.098 626.705 503.142 585.427 515.618 539.811C528.094 494.196 530.757 445.136 523.455 395.434C516.153 345.732 499.029 296.361 473.061 250.139C447.093 203.917 412.789 161.75 372.109 126.046C331.428 90.3409 285.167 61.7977 235.967 42.0457C186.767 22.2937 135.591 11.7198 85.3616 10.9277C35.1323 10.1355 -13.1669 19.1407 -56.7784 37.429C-100.39 55.7173 -138.46 82.9305 -168.814 117.515L140.965 389.402L450.744 661.29Z"
                      fill="url(#paint1_linear_1289_250)"
                      fillOpacity="0.3"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1289_250"
                      x1="378.223"
                      y1="119.079"
                      x2="273.601"
                      y2="224.283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#800080" />
                      <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1289_250"
                      x1="378.223"
                      y1="119.079"
                      x2="273.601"
                      y2="224.283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#800080" />
                      <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="freelance-card-main">
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img
                      src={free1}
                      alt="Top Talent: Access skilled candidates for various roles."
                    />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Top Talent:
                    </span>{" "}
                    Access skilled candidates for various roles.
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img
                      src={free2}
                      alt="Fresh Ideas: Bring fresh perspectives with talented interns."
                    />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Fresh Ideas:
                    </span>{" "}
                    Bring fresh perspectives with talented interns.
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free3} alt="Flexible Hiring: " />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Flexible Hiring:
                    </span>{" "}
                    Short-term interns or long-term hires.
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free4} alt="Zero Hiring Fee:" />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Zero Hiring Fee:
                    </span>{" "}
                    No cost for recruitment.
                  </p>
                </div>
              </div>
            </div>
            <div className="freelance-card">
              <div className="freelance-card-top">For Freelance Projects</div>
              <div className="freelance-card-design">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="538"
                  height="343"
                  viewBox="0 0 538 343"
                  fill="none"
                >
                  <g opacity="0.3">
                    <path
                      d="M458.938 668.481C490.095 632.982 512.723 590.612 525.528 543.79C538.334 496.968 541.068 446.611 533.572 395.594C526.077 344.577 508.501 293.9 481.846 246.455C455.191 199.011 419.98 155.728 378.223 119.079C336.466 82.43 288.981 53.1318 238.48 32.8573C187.978 12.5829 135.449 1.72929 83.8909 0.916199C32.3329 0.103113 -17.2439 9.34648 -62.0089 28.1185C-106.774 46.8906 -145.851 74.8237 -177.008 110.323L140.965 389.402L458.938 668.481Z"
                      fill="url(#paint0_linear_1289_250)"
                      fillOpacity="0.3"
                    />
                    <path
                      d="M450.744 661.29C481.098 626.705 503.142 585.427 515.618 539.811C528.094 494.196 530.757 445.136 523.455 395.434C516.153 345.732 499.029 296.361 473.061 250.139C447.093 203.917 412.789 161.75 372.109 126.046C331.428 90.3409 285.167 61.7977 235.967 42.0457C186.767 22.2937 135.591 11.7198 85.3616 10.9277C35.1323 10.1355 -13.1669 19.1407 -56.7784 37.429C-100.39 55.7173 -138.46 82.9305 -168.814 117.515L140.965 389.402L450.744 661.29Z"
                      fill="url(#paint1_linear_1289_250)"
                      fillOpacity="0.3"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1289_250"
                      x1="378.223"
                      y1="119.079"
                      x2="273.601"
                      y2="224.283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#800080" />
                      <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1289_250"
                      x1="378.223"
                      y1="119.079"
                      x2="273.601"
                      y2="224.283"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#800080" />
                      <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="freelance-card-main">
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free5} alt="Expert Freelancers:" />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Expert Freelancers:
                    </span>{" "}
                    Hire vetted experts for your projects.
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free6} alt="Fast, Affordable & Quality:" />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Fast, Affordable & Quality:
                    </span>{" "}
                    Quick delivery of high-quality work at competitive rates.
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free7} alt="Flexible Engagement:" />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Flexible Engagement:
                    </span>{" "}
                    Scale resources as per project needs
                  </p>
                </div>
                <div className="freelance-card-row">
                  <div className="freelance-card-row-icon">
                    <img src={free8} alt="Post-Project Support:" />
                  </div>
                  <p className="freelance-card-para">
                    <span className="freelance-card-para-head">
                      Post-Project Support:
                    </span>{" "}
                    Continued support after project completion.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="freelance-main-bottom"
            onClick={() =>
              (window.location.href =
                "https://docs.google.com/forms/d/e/1FAIpQLSdvoG0X5yAdJbaXJYehoAIYOTDp5elVV2M_pkAZALnRTwClWg/viewform")
            }
          >
            Contact us
          </div>
        </div>
      </div>
      <div className="freelance-contact">
        For any query contact here: +917302182091
      </div>
      <IntroFooter num="+917302182091" />
    </div>
  );
}

export default Freelance;
