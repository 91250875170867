import React from "react";
import lamp1 from "./Popper.svg";
import rb2535611 from "./diwalirb.png";
import "./style.css";

export const DiwaliStrip = () => {
  return (
    <div className="diwali-strip">
      <img className="rb" alt="Rb" src={rb2535611} />

      <div className="diwali-strip-text">
        <img className="lamp" alt="Lamp" src={lamp1} />

        <div className="text-wrapper">
          Limited 100% refund offer{" "}
          <span
            className="div"
            onClick={() => (window.location.href = "#how-refund")}
          >
            Know More
          </span>
        </div>

        <img
          className="lamp"
          style={{ transform: "scaleX(-1)" }}
          alt="Lamp"
          src={lamp1}
        />
      </div>

      <div className="frame-2">
        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />
      </div>
    </div>
  );
};
