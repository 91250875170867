import React from "react";
import "./Styles/WhyChooseUs.css";
import wcu1 from "../Assests/latest/wcu1.svg";
import wcu2 from "../Assests/latest/wcu2.svg";
import wcu3 from "../Assests/latest/wcu3.svg";
import wcu4 from "../Assests/latest/wcu4.svg";
import wcu5 from "../Assests/latest/wcu5.svg";
import wcu6 from "../Assests/latest/wcu6.svg";
const WhyChooseUs = () => {
  return (
    <div className="wcu-cont">
      <div className="headings-col">Why choose us ?</div>
      <div className="wcu-inner-cont">
        <div className="wcu-card-cont">
          {/* card 1 */}
          <div className="wcu-card">
            <img src={wcu3} alt="wcu1" />
            <div className="wcu-card-head">
              1. 100% FREE Learning
              <span>
                On the courses enrolled within the 100% Refund offer through
                which you earn your enrollment fee back as reward
              </span>
            </div>
          </div>

          {/* card 2 */}

          <div className="wcu-card">
            <img src={wcu2} alt="wcu1" />
            <div className="wcu-card-head">
              2. Structured Course
              <span>
                Learn from our structured pre recorded courses made by experts
                to meet industry needs
              </span>
            </div>
          </div>
        </div>
        <div className="wcu-card-cont wcu-hr-cont">
          <div className="wcu-card wcu-hr"></div>
          <div className="wcu-card wcu-hr"></div>
        </div>
        <div className="wcu-card-cont">
          {/* card 3 */}
          <div className="wcu-card">
            <img src={wcu5} alt="wcu1" />
            <div className="wcu-card-head">
              3. Instant mentor support
              <span>
                Personal mentors to guide and help you throughout your journey
                as a friend through chat, calls & screen sharing
              </span>
            </div>
          </div>

          {/* card 4 */}
          <div className="wcu-card">
            <img src={wcu4} alt="wcu1" />
            <div className="wcu-card-head">
              4. Projects & Certifications
              <span>
                Build major projects which makes your resume stand apart
                alongwith course completion certifications
              </span>
            </div>
          </div>
        </div>
        <div className="wcu-card-cont wcu-hr-cont">
          <div className="wcu-card wcu-hr"></div>
          <div className="wcu-card wcu-hr"></div>
        </div>
        <div className="wcu-card-cont">
          {/* card 5 */}
          <div className="wcu-card">
            <img src={wcu1} alt="wcu1" />
            <div className="wcu-card-head">
              5. Internship Opportunity{" "}
              <span>
                Get remote internship opportunities after the completion of the
                course along with dedicated assignments
              </span>
            </div>
          </div>
          {/* card 6 */}
          <div className="wcu-card">
            <img src={wcu6} alt="wcu1" />
            <div className="wcu-card-head">
              6. Lifetime course access
              <span>
                Get liftetime course access on each course that you enroll, and
                enjoy the benefit of mentoring wheneve you want
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
