import React, { useEffect, useRef, useState } from "react";
import "./layout.css";
import "../global.css";
import Navbar from "../../Components/HomePage/Components/Navbar";
import Experiences from "../../Components/Experiences";
// import Faq from "../../InternshipTrack/pages/home/Faq";
// import Footer from "../../InternshipTrack/pages/home/Footer";
// import Hero from "./home/Hero";
// import Refund from "./home/Refund";
import Courses from "./home/Courses";
// import OtherCourses from "./home/OtherCourses";
// import CustomPackRefund from "./home/CustomPackRefund";
import { Helmet } from "react-helmet";
import SignUp from "../../Components/SignUp";
import Login from "../../Components/Login";
import ForgotPass from "../../Components/ForgotPass";
import FloatSelected from "../../Components/CustomPack/FloatSelected";
import VideoCarousel from "../../Components/VideoCarousel";
import Hero from "../../NewSections/Hero";
import Backoffer from "../../NewSections/BackOffer";
import Best from "../../NewSections/Best";
import Certificate from "../../NewSections/Certificate";
import { useNavigate } from "react-router-dom";
import useCart from "../../contexts/Cart";
import Cookies from "js-cookie";
import IntroFaq from "../../Components/IntroFaq";
import IntroFooter from "../../Components/IntroFooter";
import { DiwaliStrip } from "../../Components/DiwaliStrip/DiwaliStrip";

function Layout(props) {
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const customSlideToExploreRef = useRef(null);
  const [showForgot, setShowForgot] = useState(false);
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "INR",
        value: 1499.0,
        items: [
          {
            item_id: "custompack",
            item_name: "Custom Pack page",
            price: 1499.0,
          },
        ],
      },
    });
  }, []);
  const breadcrumbJsonLd = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://tutedude.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Customized Pack",
        item: "https://tutedude.com/category/customizepack",
      },
    ],
  };
  const { setSelectedCategory, selectedCourse } = useCart();
  const navigate = useNavigate();
  const onCLick = () => {
    setSelectedCategory("");
    if (!Cookies.get("user_email")) {
      if (selectedCourse?.customPack?.length > 0) {
        setRedirect("/packpayment");
        setShowLogin(true);
      } else {
        setRedirect(null);
        window.location.href = "#choose";
      }
    } else {
      if (selectedCourse?.customPack?.length > 0) {
        navigate(`/packpayment`);
      } else {
        window.location.href = "#choose";
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Customized Pack page</title>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJsonLd)}
        </script>
        <link
          rel="canonical"
          href={`https://tutedude.com/category/customizepack`}
          data-react-helmet="true"
        />
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          redirect={redirect}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      <div className="ctm-pack-layout">
        <Navbar setShowLogin={setShowLogin} setShowSign={setShowSign} />
        {/* <Hero setShowLogin={setShowSign} setRedirect={setRedirect} /> */}
        <Hero
          onClick={onCLick}
          head1={"Create Your Own "}
          head2={"Combo Pack Of 4+ Courses"}
          discprice={"₹2796"}
          price={"₹1499"}
          key={"Combo Pack"}
        />
        {showFloatSelected && <DiwaliStrip />}
        {/* <Refund /> */}
        <Backoffer price="1499" deadline="12" />
        <div ref={customSlideToExploreRef}>
          {/* <CustomPackRefund /> */}
          <Courses />
        </div>
        <Best />
        <Certificate text="Selected Courses" count="6" />
        {/* <OtherCourses custom={true} /> */}
        <VideoCarousel />
        <Experiences />
        <IntroFaq />
        <IntroFooter />
        {showFloatSelected && (
          <FloatSelected setShowLogin={setShowSign} setRedirect={setRedirect} />
        )}
      </div>
    </>
  );
}

export default Layout;
