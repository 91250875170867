import React, { useEffect, useRef, useState } from "react";
import "./global.css";
import "./UpgradePro.css";
import Hero from "./home/Hero";
import Refund from "./home/Refund";
import UpgradeCourses from "./home/UpgradeCourses";
import Highlights from "../InternshipTrack/pages/home/Highlights";
import VideoCarousel from "../Components/VideoCarousel";
import Footer from "../InternshipTrack/pages/home/Footer";
import DoubtSolve from "../Components/Reusable/DoubtSolve";
import { useNavigate, useParams } from "react-router-dom";
import Faq from "../InternshipTrack/pages/home/Faq";
import ProPackCards from "../Components/HomePage/Components/ProPackCards";
import Cookies from "js-cookie";
import propackData from "./Contents/propackData";
import SignUp from "../Components/SignUp";
import Login from "../Components/Login";
import ForgotPass from "../Components/ForgotPass";
import { Helmet } from "react-helmet";
import Navbar from "../Components/HomePage/Components/Navbar";
import useCart from "../contexts/Cart";

function UpgradePro() {
  const { slug } = useParams();
  const email = Cookies.get("user_email");
  const [proPack, setProPack] = useState(propackData[slug]);
  const [notPurchasedCourses, setNotPurchasedCourses] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [redirect, setRedirect] = useState(null);
  const [price, setPrice] = useState(1999);
  const [showForgot, setShowForgot] = useState(false);
  const { setSelectedCategory, setSelectedCourse } = useCart();
  const ids = [
    "datascientist",
    "appdev",
    "designing",
    "business",
    "fullstackdev",
    "techgeek",
  ];

  const breadcrumbJsonLd = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://tutedude.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: propackData[slug]?.pagetitle,
        item: `https://tutedude.com/upgrade/pro/${slug}`,
      },
    ],
  };

  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const customSlideToExploreRef = useRef(null);

  const navigate = useNavigate();

  const getCoursesToPurchase = async (propack) => {
    const notPurchasedCourses = propack?.curric?.filter(
      (item) => item.purchased === false
    );
    setNotPurchasedCourses(notPurchasedCourses);
    if (notPurchasedCourses?.length <= 3) {
      setPrice(799);
    } else if (notPurchasedCourses?.length <= 5) {
      setPrice(1299);
    } else {
      setPrice(1999);
    }
  };

  const fetchAndAddPurchaseStatus = async (email, slug) => {
    try {
      const response = await fetch(
        `https://api.tutedude.com/studentinfo?student_id=${email}`,
        {
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();

      if (!data.success) {
        throw new Error("Failed to fetch user data");
      }

      const purchasedSlugsSet = new Set(
        data?.dashboard?.stdata?.flatMap((item) => item.sub_category.split(","))
      );

      setProPack((prev) => {
        const updatedCurric = prev.curric.map((item) => {
          return {
            ...item,
            purchased: purchasedSlugsSet.has(item.url),
          };
        });

        return {
          ...prev,
          curric: updatedCurric,
        };
      });
    } catch (error) {
      console.error("Error fetching purchase data:", error);
    }
  };

  useEffect(() => {
    if (email) fetchAndAddPurchaseStatus(email, slug);
  }, [slug, email]);

  useEffect(() => {
    getCoursesToPurchase(proPack);
  }, [proPack]);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.gtag("event", "conversion", {
      send_to: "AW-711435738/CRmfCMKls7oDENrLntMC",
    });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        currency: "INR",
        value: 2999.0,
        items: [
          {
            item_id: "upgradepro" + slug,
            item_name: propackData[slug]?.pagetitle,
            price: 2999.0,
          },
        ],
      },
    });
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!ids.includes(slug)) {
    navigate("/");
    return <>Wrong Url...</>;
  }

  return (
    <>
      <Helmet>
        <title>Upgrade Pro Pack</title>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJsonLd)}
        </script>
        <link
          rel="canonical"
          href={`https://tutedude.com/upgrade/pro/${slug}`}
          data-react-helmet="true"
        />
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>
      {showSign && (
        <SignUp
          setShowSign={setShowSign}
          redirect={redirect}
          setShowLogin={setShowLogin}
        />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          redirect={redirect}
          setShowSign={setShowSign}
          setShowForgot={setShowForgot}
        />
      )}
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}

      <div className="abcd-layout">
        <Navbar setShowSign={setShowSign} setShowLogin={setShowLogin} />
        <Hero
          price={price}
          courses={proPack.curric.length}
          head={proPack.heroHead}
          notPurchasedCourses={notPurchasedCourses}
          setRedirect={setRedirect}
          setShowLogin={setShowSign}
        />
        <div ref={customSlideToExploreRef}>
          <UpgradeCourses
            slug={slug}
            price={price}
            proPack={proPack}
            notPurchasedCourses={notPurchasedCourses}
            setRedirect={setRedirect}
            setShowLogin={setShowSign}
          />
        </div>
        <Refund />
        <Highlights />
        <DoubtSolve />
        <ProPackCards slug={slug} />
        <VideoCarousel />
        <Faq />
        <Footer />
      </div>

      {showFloatSelected && (
        <div className="abcd-layout-bottom">
          <div className="abcd-hm-left">
            <span className="">
              {proPack?.curric.length === 6
                ? Math.ceil(((1999 - price) / 1999) * 100)
                : Math.ceil(((1499 - price) / 1499) * 100)}
              %{" "}
            </span>
            <span className="">OFF</span>
          </div>
          <div className="abcd-hm-middle">
            <p className="abcd-fw-700 abcd-layout-price-bottom abcd-text-pink abcd-text-center">
              <span className="abcd-layout-bottom-small">₹{price}</span>
            </p>
            <p
              className="abcd-fw-700 abcd-f-16 abcd-text-pink abcd-text-center"
              style={{ color: "#263238" }}
            >
              Total Price
            </p>
          </div>
          <div className="abcd-hm-right">
            <div
              className="abcd-proceed abcd-layout-proceed abcd-text-white"
              onClick={() => {
                setSelectedCategory("upgrade-pro");
                setSelectedCourse((prev) => ({
                  ...prev,
                  upgrade: notPurchasedCourses,
                }));
                if (!Cookies.get("user_email")) {
                  setShowLogin(true);
                } else {
                  if (notPurchasedCourses?.length > 0) {
                    navigate("/packpayment");
                  } else {
                    alert("Already purchased all courses");
                    window.location.href =
                      "https://upskill.tutedude.com/dashboard";
                  }
                }
              }}
            >
              Upgrade Now
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpgradePro;
