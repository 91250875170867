import "./backoffer.css";
import O1 from "../Assests/gif1.gif";
import O2 from "../Assests/gif2.gif";
import O3 from "../Assests/gif3.gif";
import graduate from "./assets/graduate.svg";
import moneyback from "./assets/moneyback.svg";
function Backoffer({ price, deadline, allAccess }) {
  return (
    <section className="alac-pack-new-backoffer-section" id="how-refund">
      <div className="alac-pack-new-backoffer">
        <div className="alac-pack-new-back-offer-heading">
          <p className="alac-pack-new-f-48 alac-pack-new-fw-400 alac-pack-new-text-white alac-pack-new-text-center">
            <span className="alac-pack-new-fw-700">
              How to Learn <br /> for Free
            </span>
          </p>
        </div>
        <div className="alac-pack-new-backoffer-bottom">
          <div className="alac-pack-new-backoffer-left">
            <div className="alac-pack-new-bol-card alac-pack-new-bol-card1">
              <div className="alac-pack-new-bol-card-left">
                <img src={O1} alt="Enroll" />
              </div>
              <div className="alac-pack-new-bol-card-middle">
                <div className="alac-pack-new-bol-card-heading fw-600">
                  <span className="fw-700 text-pink">Enroll</span>
                  {" By Paying "}
                  <br />
                  <span className="fw-700 text-pink">₹{price}</span>
                </div>
              </div>
              <div className="alac-pack-new-bol-card-right">1</div>
            </div>
            <div className="alac-pack-new-bol-card alac-pack-new-bol-card2">
              <div className="alac-pack-new-bol-card-left">
                <img src={O2} alt="Course" />
              </div>
              <div className="alac-pack-new-bol-card-middle">
                <div className="alac-pack-new-bol-card-heading fw-600">
                  <span className="fw-700 text-pink">
                    Complete {allAccess ? "any 8" : ""} Courses
                  </span>
                  {!allAccess
                    ? ` within ${deadline} Months`
                    : " Within 2 Years"}
                </div>
              </div>
              <div className="alac-pack-new-bol-card-right">2</div>
            </div>
            <div className="alac-pack-new-bol-card alac-pack-new-bol-card3">
              <div className="alac-pack-new-bol-card-left">
                <img src={O3} alt="Payment" />
              </div>
              <div className="alac-pack-new-bol-card-middle">
                <div className="alac-pack-new-bol-card-heading fw-600">
                  Get
                  <span className="fw-700 text-pink">{" 100% Refund "}</span>
                  Back into Your bank
                </div>
              </div>
              <div className="alac-pack-new-bol-card-right">3</div>
            </div>
          </div>
          <div className="alac-pack-new-backoffer-right">
            <img src={graduate} alt="Graduate" />
          </div>
          <img src={moneyback} alt="Money Back" className="money-back" />
        </div>
      </div>
    </section>
  );
}

export default Backoffer;
