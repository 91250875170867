import { useNavigate } from "react-router-dom";
import "./ProPackCardSingle.css";

const ProPackCardSingle = ({ card, index }) => {
  const navigate = useNavigate();
  return (
    <div className="afternoon-card">
      <div className="afternoon-card-top">
        <div className="afternoon-card-main-img">
          <img src={card.icon} alt={card.heading} />
        </div>
        <div className="afternoon-card-heading">
          <h3>{card.heading}</h3>
        </div>
      </div>
      <div className="afternoon-card-middle">
        {card.company.map((comp, idx) => {
          return (
            <div className="afternoon-card-company" key={`${index} ${idx}`}>
              <div className="afternoon-card-company-img">
                <img src={comp.icon} alt={comp.title} />
                {/* {comp.icon} */}
              </div>
              <p className="afternoon-card-company-title">{comp.title}</p>
            </div>
          );
        })}
      </div>
      <div className="afternoon-card-bottom">
        <div className="afternoon-card-bottom-price">
          <p className="afternoon-card-price">{card.price}</p>
          <p className="afternoon-card-discount">{card.discount}</p>
          <p className="afternoon-card-off">{card.off}</p>
        </div>
        <div className="afternoon-bottom-validity">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
          >
            <mask
              id="mask0_1_55"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="18"
              height="19"
            >
              <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1_55)">
              <path
                d="M8.25073 13.2496H9.75073V8.74963H8.25073V13.2496ZM9.00073 7.24963C9.21323 7.24963 9.39136 7.17776 9.53511 7.03401C9.67886 6.89026 9.75073 6.71213 9.75073 6.49963C9.75073 6.28713 9.67886 6.10901 9.53511 5.96526C9.39136 5.82151 9.21323 5.74963 9.00073 5.74963C8.78823 5.74963 8.61011 5.82151 8.46636 5.96526C8.32261 6.10901 8.25073 6.28713 8.25073 6.49963C8.25073 6.71213 8.32261 6.89026 8.46636 7.03401C8.61011 7.17776 8.78823 7.24963 9.00073 7.24963ZM9.00073 16.9996C7.96323 16.9996 6.98823 16.8028 6.07573 16.409C5.16323 16.0153 4.36948 15.4809 3.69448 14.8059C3.01948 14.1309 2.48511 13.3371 2.09136 12.4246C1.69761 11.5121 1.50073 10.5371 1.50073 9.49963C1.50073 8.46213 1.69761 7.48713 2.09136 6.57463C2.48511 5.66213 3.01948 4.86838 3.69448 4.19338C4.36948 3.51838 5.16323 2.98401 6.07573 2.59026C6.98823 2.19651 7.96323 1.99963 9.00073 1.99963C10.0382 1.99963 11.0132 2.19651 11.9257 2.59026C12.8382 2.98401 13.632 3.51838 14.307 4.19338C14.982 4.86838 15.5164 5.66213 15.9101 6.57463C16.3039 7.48713 16.5007 8.46213 16.5007 9.49963C16.5007 10.5371 16.3039 11.5121 15.9101 12.4246C15.5164 13.3371 14.982 14.1309 14.307 14.8059C13.632 15.4809 12.8382 16.0153 11.9257 16.409C11.0132 16.8028 10.0382 16.9996 9.00073 16.9996ZM9.00073 15.4996C10.6757 15.4996 12.0945 14.9184 13.257 13.7559C14.4195 12.5934 15.0007 11.1746 15.0007 9.49963C15.0007 7.82463 14.4195 6.40588 13.257 5.24338C12.0945 4.08088 10.6757 3.49963 9.00073 3.49963C7.32573 3.49963 5.90698 4.08088 4.74448 5.24338C3.58198 6.40588 3.00073 7.82463 3.00073 9.49963C3.00073 11.1746 3.58198 12.5934 4.74448 13.7559C5.90698 14.9184 7.32573 15.4996 9.00073 15.4996Z"
                fill="#800080"
                fillOpacity="0.87"
              />
            </g>
          </svg>
          <p className="afternoon-bottom-validity-para">
            You get a Refund Validity of <b>2 Years</b>
          </p>
        </div>
        <p
          className="afternoon-bottom-know pointer"
          onClick={() => navigate(`/category/${card?.slug}`)}
        >
          Know More
        </p>
      </div>
    </div>
  );
};

export default ProPackCardSingle;
