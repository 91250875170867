import React, { useEffect, useState } from "react";
import "./ForgotPassword.css";
import VerficationCode from "./VerficationCode";
import Cookies from "js-cookie";

const ForgotPass = ({ className, setShowForgot }) => {
  const [details, setDetails] = useState(true);
  const [otp, setOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const otpp = Cookies.get("otpsent");
    if (otpp) {
      setDetails(false);
      setOtp(true);
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email.length > 0 || !number.length > 0) {
      alert("Please Fill email and number both");
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    await fetch(
      `https://api.tutedude.com/lms/api/user/forgot?email=${email}&number=${number}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setDetails(false);
          setOtp(true);
          Cookies.set("otpsent", true);
          Cookies.set("email", email);
        }
      })
      .catch((error) => console.error(error));
    setLoading(false);
  };
  return (
    <div className="login-cont">
      {details && (
        <form
          className={`forgot-password ${className}`}
          onSubmit={handleSubmit}
        >
          <div
            className="verification-icon"
            onClick={() => setShowForgot(false)}
          >
            <svg
              className="akar-iconscross"
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M10 10L1 1M10 1L1 10"
                stroke="white"
                stroke-width="1.79221"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <section className="password-reset-info-wrapper">
            <div className="password-reset-info">
              <div className="password-reset-title">
                <b className="forgot-password1">{`Forgot Password `}</b>
              </div>
              <div className="enter-your-email">
                Enter your Email and phone number for Verfication Code(OTP)
              </div>
            </div>
          </section>
          <section className="input-fields">
            <div className="name-field">
              <input
                className="email-id"
                placeholder="Email Id"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="name-field1">
              <input
                className="email-id"
                placeholder="WhatsApp Number"
                type="text"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
            </div>
          </section>
          <button type="submit" disabled={loading} className="button-container">
            <b className="get-otp">{loading ? "Sending..." : "Get OTP"}</b>
          </button>
        </form>
      )}
      {otp && <VerficationCode setDetails={setDetails} setOtpp={setOtp} />}
    </div>
  );
};

export default ForgotPass;
