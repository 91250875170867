import "./enrollbtn.css";
function EnrollBtn({ onClick }) {
  return (
    <>
      <div className="common-enroll-btn" onClick={onClick}>
        Enroll now
      </div>
    </>
  );
}

export default EnrollBtn;
