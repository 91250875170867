import React from "react";
import "./WhyBest.css";
import wb1 from "../../Assests/Reviews/wb1.png";
import wb2 from "../../Assests/Reviews/wb2.png";
import wb3 from "../../Assests/Reviews/wb3.png";
import wb4 from "../../Assests/Reviews/wb4.png";
import wb5 from "../../Assests/Reviews/wb5.png";
import wb6 from "../../Assests/Reviews/wb6.png";
const WhyBest = () => {
  // const chips = [
  //   {
  //     img: wb1,
  //     text: `It Covers everything from scratch`,
  //   },
  //   {
  //     img: wb2,
  //     text: "It has 1-1 Live Mentors Support",
  //   },
  //   {
  //     img: wb3,
  //     text: "Follows Project Based Learning",
  //   },
  //   {
  //     img: wb4,
  //     text: "Provides Industry recognized Certificates",
  //   },
  //   {
  //     img: wb5,
  //     text: "Provides Internship/Placement assistance",
  //   },
  //   {
  //     img: wb6,
  //     text: "Comes with Lifetime course access",
  //   },
  // ];

  const chips = [
    {
      img: wb1,
      text: (
        <>
          It <span className="new-why-best-col">Covers everything</span> from
          scratch
        </>
      ),
    },
    {
      img: wb2,
      text: (
        <>
          It has <span className="new-why-best-col">1-1 Live Mentor</span>{" "}
          Support
        </>
      ),
    },
    {
      img: wb3,
      text: (
        <>
          Follows{" "}
          <span className="new-why-best-col">Project Based Learning</span>
        </>
      ),
    },
    {
      img: wb4,
      text: (
        <>
          Provides Industry recognized{" "}
          <span className="new-why-best-col">Certification</span>
        </>
      ),
    },
    {
      img: wb5,
      text: (
        <>
          Provides{" "}
          <span className="new-why-best-col">Internship/Placement</span>{" "}
          assistance
        </>
      ),
    },
    {
      img: wb6,
      text: (
        <>
          Comes with{" "}
          <span className="new-why-best-col">Lifetime course access</span>
        </>
      ),
    },
  ];

  return (
    <div className="new-why-best-cont">
      <div className="new-why-best-head">Why its the Best Program?</div>
      <div className="new-why-best-mid">
        45000+ Students
        <div className="new-why-best-mid-inner">
          4.9{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M3.21078 15.9048L4.57483 10.0242L0 6.06893L6.04382 5.54575L8.39419 0L10.7446 5.54575L16.7884 6.06893L12.2135 10.0242L13.5776 15.9048L8.39419 12.7866L3.21078 15.9048Z"
              fill="#FBBB02"
            />
          </svg>{" "}
          rated
        </div>
      </div>
      <div className="new-why-best-chip-container">
        {chips.map((item, ind) => (
          <Chip
            key={ind}
            img={item.img}
            text={item.text}
            id={ind === chips.length - 1 ? "howScholarship" : ""}
          />
        ))}
      </div>
    </div>
  );
};

export default WhyBest;

const Chip = ({ img, text, id }) => {
  return (
    <div className="new-why-best-chip">
      <img src={img} alt="whybest" />
      <p id={id}>{text}</p>
    </div>
  );
};
