import React from "react";
import O1 from "../../../Assests/gif1.gif";
import O2 from "../../../Assests/gif2.gif";
import O3 from "../../../Assests/gif3.gif";
// import moneyback from "../../../Assests/monryback.png";
import diya from "./limitedoffer.png";
import design from "./Black.png";
import "./DiwaliHeroMobile.css";

const DiwaliHowScholarship = () => {
  return (
    <div className="new-howref-cont" id="how-refund">
      <img src={design} alt="design" className="diwali-design" />
      <div className="frame-2">
        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />

        <div className="ellipse" />

        <div className="ellipse-2" />
      </div>

      <div className="new-howref-head">
        How to Learn <br />
        for Free?
        <img src={diya} alt="diya" />
      </div>
      <div className="test-ref-cont">
        <div className="ref-new-backoffer-left test-ref-left">
          <div className="ref-new-bol-card bol-card1">
            {/* <img src={moneyback} alt="moneyback" className="moneyback-test" /> */}
            <div className="ref-new-bol-card-left">
              <img src={O1} alt="Enroll" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading">
                <span>Enroll</span> By Paying <br />
                <span>₹699</span>{" "}
              </div>
            </div>
            <div className="ref-new-bol-card-right">1</div>
          </div>
          <div className="ref-new-bol-card bol-card2">
            <div className="ref-new-bol-card-left">
              <img src={O2} alt="Course" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading">
                <span>Complete Course</span> and Submit Assignments
              </div>
            </div>
            <div className="ref-new-bol-card-right">2</div>
          </div>
          <div className="ref-new-bol-card bol-card3">
            <div className="ref-new-bol-card-left">
              <img src={O3} alt="Payment" />
            </div>
            <div className="ref-new-bol-card-middle test-ref-middle">
              <div className="test-ref-new-bol-card-heading">
                Get <span>100% Money Back</span> into Your bank
              </div>
            </div>
            <div className="ref-new-bol-card-right">3</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiwaliHowScholarship;
