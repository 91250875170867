import React from 'react'
import "./styles/resumeBuilding.css";
import ResumeBuildingIcon from "./assets/resumeBuildingIcon.svg"
import resumeIcon11 from "./assets/resumeIcon11.svg"
import resumeIcon12 from "./assets/resumeIcon12.svg"
import resumeIcon13 from "./assets/resumeIcon13.svg"
import resumeIcon14 from "./assets/resumeIcon14.svg"

const ResumeBuilding = () => {

    const data = [
        {
            icon: resumeIcon11,
            text: "Professional Editable Resume"
        },
        {
            icon: resumeIcon12,
            text: "Easy Export: Download and share"
        },
        {
            icon: resumeIcon13,
            text: "Job Opportunities"
        },
        {
            icon: resumeIcon14,
            text: "Career Support"
        },
    ]

  return (
    <div className='resume-container'>
        <div className='resume-content'>
            <div className='resume-left'>
                <div className='resume-heading-resp'>
                    <span className='resume-heading-text'>Resume Building with</span>
                        <span className='resume-heading-internship'>{" Internship Assistance "}</span>
                    <span className='resume-heading-text'>in One Place</span>
                </div>

                <div className='resume-left-icon'>
                    <img className='' src={ResumeBuildingIcon} alt='resumebuildingicon'/>
                </div>
            </div>

            <div className='resume-right'>
                <div className='resume-heading'>
                    <span className='resume-heading-text'>Resume Building with</span>
                        <span className='resume-heading-internship'>{" Internship Assistance "}</span>
                    <span className='resume-heading-text'>in One Place</span>
                </div>

                <div className='resume-feature-container'>

                    <div className='resume-feature-container-background'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="490" height="260" viewBox="0 0 490 260" fill="none" className='background-svg'>
                            <g opacity="0.3">
                                <path d="M79.2574 -408.501C48.1002 -373.002 25.4727 -330.631 12.6669 -283.809C-0.138911 -236.987 -2.87229 -186.63 4.62283 -135.613C12.1179 -84.5967 29.6947 -33.9194 56.3496 13.5251C83.0045 60.9695 118.216 104.252 159.972 140.901C201.729 177.55 249.214 206.849 299.716 227.123C350.217 247.398 402.746 258.251 454.304 259.064C505.862 259.877 555.439 250.634 600.204 231.862C644.969 213.09 684.046 185.157 715.203 149.657L397.23 -129.422L79.2574 -408.501Z" fill="url(#paint0_linear_114_4046)" fill-opacity="0.3"/>
                                <path d="M87.4516 -401.309C57.0974 -366.725 35.0531 -325.446 22.5773 -279.831C10.1015 -234.215 7.43855 -185.156 14.7405 -135.454C22.0424 -85.752 39.1663 -36.3806 65.1343 9.84119C91.1023 56.063 125.406 98.2301 166.087 133.935C206.767 169.64 253.029 198.183 302.229 217.935C351.429 237.687 402.604 248.261 452.834 249.053C503.063 249.845 551.362 240.84 594.974 222.551C638.585 204.263 676.655 177.05 707.009 142.465L397.23 -129.422L87.4516 -401.309Z" fill="url(#paint1_linear_114_4046)" fill-opacity="0.3"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_114_4046" x1="159.972" y1="140.901" x2="264.595" y2="35.6977" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#800080"/>
                                    <stop offset="1" stop-color="#FF864C" stop-opacity="0.27"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_114_4046" x1="159.972" y1="140.901" x2="264.595" y2="35.6977" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#800080"/>
                                    <stop offset="1" stop-color="#FF864C" stop-opacity="0.27"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>

                    <div className='resume-feature-container-background-resp'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="343" height="161" viewBox="0 0 343 161" fill="none">
                            <g opacity="0.3">
                                <path d="M345.686 516.384C369.785 488.927 387.286 456.156 397.19 419.941C407.095 383.726 409.209 344.778 403.412 305.319C397.615 265.86 384.02 226.663 363.404 189.967C342.788 153.271 315.554 119.794 283.257 91.448C250.96 63.1016 214.233 40.4408 175.172 24.7595C136.112 9.07822 95.4829 0.683486 55.6054 0.0545996C15.7278 -0.574283 -22.6174 6.575 -57.241 21.0943C-91.8646 35.6136 -122.089 57.2185 -146.187 84.6755L99.7495 300.53L345.686 516.384Z" fill="url(#paint0_linear_114_4471)" fill-opacity="0.3"/>
                                <path d="M339.348 510.821C362.826 484.072 379.876 452.145 389.525 416.864C399.175 381.582 401.234 343.637 395.587 305.195C389.939 266.753 376.695 228.567 356.61 192.817C336.525 157.066 309.992 124.452 278.528 96.8361C247.063 69.2202 211.282 47.1434 173.229 31.8662C135.175 16.589 95.5928 8.41065 56.7429 7.79796C17.893 7.18529 -19.4641 14.1503 -53.1955 28.2955C-86.9268 42.4406 -116.372 63.4887 -139.849 90.2381L99.7495 300.53L339.348 510.821Z" fill="url(#paint1_linear_114_4471)" fill-opacity="0.3"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_114_4471" x1="283.257" y1="91.448" x2="202.337" y2="172.818" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#800080"/>
                                <stop offset="1" stop-color="#FF864C" stop-opacity="0.27"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_114_4471" x1="283.257" y1="91.448" x2="202.337" y2="172.818" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#800080"/>
                                <stop offset="1" stop-color="#FF864C" stop-opacity="0.27"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>

                    <div className='resume-feature-subcontainer'>
                        {
                            data.map( (item, key) => (          
                                <div className='resume-feature' key={key}>             
                                    <img src={item.icon}/>
                                    <span>{item.text}</span>
                                </div>                                
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ResumeBuilding