import React, { useState } from "react";
import "../../styles/roadmap.css";
import Curriculum from "../../../Components/Curriculum";
import contents from "../../../contents/Contents.json";
import "../../../Components/InternshipTracks/Modal.css";
import "../../../Components/InternshipTracks/Recipe.css";
function Roadmap({ data }) {
  const [slug, setSlug] = useState("");
  const [modal, setModal] = useState(false);
  return (
    <section className="int-track-section int-track-section-roadmap">
      {modal && (
        <div className="curr-modal">
          <div className="curr-modal1">
            <div class="curriculum-name disFlex">
              <p>
                Curriculum: <br />
                {contents[slug]?.name}
              </p>
              <span
                class="step-close"
                onClick={() => {
                  document.body.classList.remove("noScroll");
                  setModal(false);
                }}
              >
                ×
              </span>
            </div>

            <Curriculum
              slug={slug}
              hideDetails={true}
              topics1={contents[slug]?.topics1}
              topics2={contents[slug]?.topics2}
              details={contents[slug]?.details}
            />
          </div>
        </div>
      )}
      <div className="int-track-roadmap">
        <div className="int-track-roadmap-heading int-track-f-48 int-track-fw-500 int-track-text-center">
          <p>Roadmap to</p>
          <p className="int-track-fw-700"> {data?.curricHead}</p>
        </div>

        <div className="int-track-roadmap-card-top">
          <div className="int-track-rct-l">
            {data?.curric.map((curr, ind) => (
              <div className="int-track-card-num-div" key={ind}>
                <div className="int-track-card-num">
                  <p>{ind + 1}</p>
                </div>
              </div>
            ))}
            {/* <div className="int-track-card-num-div">
              <div className="int-track-card-num">
                <p>1</p>
              </div>
            </div>
            <div className="int-track-card-num-div">
              <div className="int-track-card-num">
                <p>2</p>
              </div>
            </div>
            <div className="int-track-card-num-div">
              <div className="int-track-card-num">
                <p>3</p>
              </div>
            </div>
            <div className="int-track-card-num-div int-track-card-num-div-4">
              <div className="int-track-card-num">
                <p>4</p>
              </div>
            </div> */}
          </div>
          <div className="int-track-rct-m">
            {data?.curric.map((curr, ind) => (
              <div
                className={`int-track-rct-m-${ind + 1}`}
                key={ind}
                style={{
                  ...(ind === data?.curric.length - 1 && {
                    borderBottom: "none",
                  }),
                }}
              >
                {ind !== data?.curric.length - 1 && (
                  <div className="int-track-roadmap-vertical-line"></div>
                )}
              </div>
            ))}
            {/* <div className="int-track-rct-m-1">
              <div className="int-track-roadmap-vertical-line"></div>
            </div>
            <div className="int-track-rct-m-2">
              <div className="int-track-roadmap-vertical-line"></div>
            </div>
            <div className="int-track-rct-m-3">
              <div className="int-track-roadmap-vertical-line"></div>
            </div>
            <div className="int-track-rct-m-4"></div> */}
          </div>
          <div className="int-track-rct-r">
            {data?.curric.map((curr, ind) => (
              <div className="int-track-rct-r-1 int-track-rct-r-card" key={ind}>
                <div className="int-track-rct-r--t">
                  <div className="int-track-rct-r-heading">{curr?.name}</div>
                  <div className="int-track-rct-r-btn">
                    <svg
                      className="int-track-roadmap-card-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0013 2.13892C5.4013 2.13892 1.66797 5.68558 1.66797 10.0556C1.66797 14.4256 5.4013 17.9722 10.0013 17.9722C14.6013 17.9722 18.3346 14.4256 18.3346 10.0556C18.3346 5.68558 14.6013 2.13892 10.0013 2.13892ZM10.8346 14.0139H9.16797V9.26392H10.8346V14.0139ZM10.8346 7.68058H9.16797V6.09725H10.8346V7.68058Z"
                        fill="white"
                      />
                    </svg>
                    <p
                      className="pointer"
                      onClick={() => {
                        document.body.classList.add("noScroll");
                        setSlug(curr.slug);
                        setModal(true);
                      }}
                    >
                      {" "}
                      Know more
                    </p>
                  </div>
                </div>
                <div className="int-track-rct-r--b">{curr?.details}</div>
              </div>
            ))}
            {/* <div className="int-track-rct-r-2 int-track-rct-r-card">
              <div className="int-track-rct-r--t">
                <div className="int-track-rct-r-heading">MERN Stack</div>
                <div className="int-track-rct-r-btn">
                  <svg
                    className="int-track-roadmap-card-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0013 2.13892C5.4013 2.13892 1.66797 5.68558 1.66797 10.0556C1.66797 14.4256 5.4013 17.9722 10.0013 17.9722C14.6013 17.9722 18.3346 14.4256 18.3346 10.0556C18.3346 5.68558 14.6013 2.13892 10.0013 2.13892ZM10.8346 14.0139H9.16797V9.26392H10.8346V14.0139ZM10.8346 7.68058H9.16797V6.09725H10.8346V7.68058Z"
                      fill="white"
                    />
                  </svg>
                  <p>Know more</p>
                </div>
              </div>
              <div className="int-track-rct-r--b">
                Learn the most demanded of web application dev with core
                technology like Mongo DB, Express.js, React & Node.js
              </div>
            </div>
            <div className="int-track-rct-r-3 int-track-rct-r-card">
              <div className="int-track-rct-r--t">
                <div className="int-track-rct-r-heading">
                  Data Structure & Algorithm
                </div>
                <div className="int-track-rct-r-btn">
                  <svg
                    className="int-track-roadmap-card-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0013 2.13892C5.4013 2.13892 1.66797 5.68558 1.66797 10.0556C1.66797 14.4256 5.4013 17.9722 10.0013 17.9722C14.6013 17.9722 18.3346 14.4256 18.3346 10.0556C18.3346 5.68558 14.6013 2.13892 10.0013 2.13892ZM10.8346 14.0139H9.16797V9.26392H10.8346V14.0139ZM10.8346 7.68058H9.16797V6.09725H10.8346V7.68058Z"
                      fill="white"
                    />
                  </svg>
                  <p>Know more</p>
                </div>
              </div>
              <div className="int-track-rct-r--b">
                Learn how the big product companies like Microsoft, Amazon,
                McKinsey and more drive their product with strong code base
              </div>
            </div>
            <div className="int-track-rct-r-4 int-track-rct-r-card">
              <div className="int-track-rct-r--t">
                <div className="int-track-rct-r-heading">
                  Competitve Programming
                </div>
                <div className="int-track-rct-r-btn">
                  <svg
                    className="int-track-roadmap-card-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10.0013 2.13892C5.4013 2.13892 1.66797 5.68558 1.66797 10.0556C1.66797 14.4256 5.4013 17.9722 10.0013 17.9722C14.6013 17.9722 18.3346 14.4256 18.3346 10.0556C18.3346 5.68558 14.6013 2.13892 10.0013 2.13892ZM10.8346 14.0139H9.16797V9.26392H10.8346V14.0139ZM10.8346 7.68058H9.16797V6.09725H10.8346V7.68058Z"
                      fill="white"
                    />
                  </svg>
                  <p>Know more</p>
                </div>
              </div>
              <div className="int-track-rct-r--b">
                Developers just don’t write code, they write production level
                robust code. Learn to write the code that can be deployed
              </div>
            </div> */}
          </div>
        </div>
        <div className="int-track-roadmap-card-bottom">
          <div className="int-track-rcb-card">
            <div className="int-track-rcb-top">
              <div className="int-track-rcb-top-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="22"
                  viewBox="0 0 37 22"
                  fill="none"
                >
                  <path
                    d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                    fill="url(#paint0_linear_23_1145)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_23_1145"
                      x1="0"
                      y1="11"
                      x2="37"
                      y2="11"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0391675" stopColor="#800080" />
                      <stop offset="0.527799" stopColor="#B466C1" />
                      <stop offset="0.92416" stopColor="#800080" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="int-track-rcb-top-heading">Real Projects</div>
            </div>
            <div className="int-track-rcb-bottom">
              Get ready to work on the creative projects, designed to craft your
              skills learned, and build a strong portfolio
            </div>
          </div>
          <div className="int-track-rcb-card">
            <div className="int-track-rcb-top">
              <div className="int-track-rcb-top-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="22"
                  viewBox="0 0 37 22"
                  fill="none"
                >
                  <path
                    d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                    fill="url(#paint0_linear_23_1145)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_23_1145"
                      x1="0"
                      y1="11"
                      x2="37"
                      y2="11"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0391675" stopColor="#800080" />
                      <stop offset="0.527799" stopColor="#B466C1" />
                      <stop offset="0.92416" stopColor="#800080" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="int-track-rcb-top-heading">Resume Building</div>
            </div>
            <div className="int-track-rcb-bottom">
              Get ready to flaunt your skills and crack any Web based Full Stack
              Development interview
            </div>
          </div>
          <div className="int-track-rcb-card">
            <div className="int-track-rcb-top">
              <div className="int-track-rcb-top-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37"
                  height="22"
                  viewBox="0 0 37 22"
                  fill="none"
                >
                  <path
                    d="M0 12.8361L8.89591 22L11.1398 19.6721L2.25979 10.5246M34.7402 0L17.9032 17.3607L11.283 10.5246L9.00731 12.8361L17.9032 22L37 2.32787M27.9927 2.32787L25.7488 0L15.6434 10.4098L17.9032 12.7213L27.9927 2.32787Z"
                    fill="url(#paint0_linear_23_1145)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_23_1145"
                      x1="0"
                      y1="11"
                      x2="37"
                      y2="11"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop offset="0.0391675" stopColor="#800080" />
                      <stop offset="0.527799" stopColor="#B466C1" />
                      <stop offset="0.92416" stopColor="#800080" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="int-track-rcb-top-heading">
                Assured Industry Internship
              </div>
            </div>
            <div className="int-track-rcb-bottom">
              Get guranteed one month internship with minimum stipend of
              ₹5ooo/month on completing the course along with alll the
              assigmnets & projects
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
