import React from "react";
import "./NewDuration.css";
const Duration = () => {
  return (
    <div className="new-dur-cont">
      <div className="headings-col">Duration of Course</div>
      <div className="new-dur-lower">
        <div className="new-dur-lower-card">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="50"
            viewBox="0 0 68 50"
            fill="none"
          >
            <path
              d="M15.4667 36.1129C7.59027 36.1129 4.96094 30.9268 4.96094 25.7408C4.96094 20.5547 7.59027 15.3687 15.4667 15.3687C25.9754 15.3687 41.734 36.1129 52.2427 36.1129C60.1191 36.1129 62.7485 30.9268 62.7485 25.7408C62.7485 20.5547 60.1191 15.3687 52.2427 15.3687C41.734 15.3687 25.9754 36.1129 15.4667 36.1129V36.1129Z"
              stroke="#6F1B8A"
              stroke-width="8.89039"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div className="new-dur-lower-text">
            Get <b>lifetime course</b> access, Even after getting{" "}
            <b>100% refund.</b>
          </div>
        </div>
        <div className="new-dur-lower-card  hideinmbile">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="62"
            viewBox="0 0 61 62"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M55.6883 25.9373H5.0625V43.6563C5.0625 45.6703 5.86257 47.6018 7.28669 49.026C8.71082 50.4501 10.6424 51.2502 12.6564 51.2502H48.0944C50.1085 51.2502 52.04 50.4501 53.4641 49.026C54.8882 47.6018 55.6883 45.6703 55.6883 43.6563V25.9373ZM26.7962 36.0624L27.0999 35.7561C27.5614 35.2791 27.817 34.6398 27.8117 33.9761C27.8064 33.3124 27.5406 32.6773 27.0716 32.2077C26.6026 31.738 25.9679 31.4714 25.3042 31.4651C24.6405 31.4589 24.0009 31.7136 23.5232 32.1744L18.8935 36.8041C18.4189 37.2788 18.1523 37.9225 18.1523 38.5937C18.1523 39.2649 18.4189 39.9086 18.8935 40.3833L23.5232 45.0105C23.7567 45.2523 24.036 45.4451 24.3448 45.5778C24.6537 45.7105 24.9858 45.7803 25.3219 45.7832C25.658 45.7861 25.9914 45.7221 26.3024 45.5948C26.6135 45.4675 26.8962 45.2796 27.1338 45.0419C27.3715 44.8042 27.5594 44.5216 27.6867 44.2105C27.814 43.8994 27.878 43.5661 27.8751 43.23C27.8722 42.8939 27.8024 42.5618 27.6697 42.2529C27.5371 41.9441 27.3442 41.6648 27.1024 41.4313L26.7962 41.125H37.9693C38.6406 41.125 39.2845 40.8583 39.7592 40.3836C40.2339 39.9089 40.5006 39.265 40.5006 38.5937C40.5006 37.9224 40.2339 37.2785 39.7592 36.8038C39.2845 36.3291 38.6406 36.0624 37.9693 36.0624H26.7962ZM12.6564 10.7495C10.6424 10.7495 8.71082 11.5496 7.28669 12.9737C5.86257 14.3978 5.0625 16.3294 5.0625 18.3434V20.8747H55.6883V18.3434C55.6883 16.3294 54.8882 14.3978 53.4641 12.9737C52.04 11.5496 50.1085 10.7495 48.0944 10.7495H12.6564Z"
              fill="#6F1B8A"
            />
          </svg>
          <div className="new-dur-lower-text">
            For <b>100% refund</b> complete course within <b>3 months.</b>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Duration;
