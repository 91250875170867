import "./curriculum.css";
import timer from "./assets/timer.svg";
import eye from "./assets/eye.svg";
import data from "./data";
import contents from "../contents/Contents.json";
import { useState } from "react";
import Curriculumm from "../Components/Curriculum";
function Curriculum({ slug }) {
  const [course, setCourse] = useState("");
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal && (
        <div className="curr-modal">
          <div className="curr-modal1">
            <div class="curriculum-name disFlex">
              <p>
                Curriculum: <br />
                {contents[course]?.name}
              </p>
              <span
                class="step-close"
                onClick={() => {
                  document.body.classList.remove("noScroll");
                  setModal(false);
                }}
              >
                ×
              </span>
            </div>

            <Curriculumm
              slug={course}
              hideDetails={true}
              topics1={contents[course]?.topics1}
              topics2={contents[course]?.topics2}
              details={contents[course]?.details}
            />
          </div>
        </div>
      )}
      <section className="section alac-pack-new-curriculum">
        <h2 className="alac-pack-new-c-heading">
          Tech Geeks Courses Curriculum
        </h2>
        <div className="alac-pack-new-c-middle">
          <p>Industry standard courses</p>
          <p>Instant Doubt Support</p>
          <p>Dedicated Placement Support</p>
          <p>Project based Learning</p>
        </div>
        <div className="alac-pack-new-c-bottom">
          {data[slug]?.curric.map((course, ind) => (
            <div className="alac-pack-new-cb-card" key={ind}>
              <div className="alac-pack-new-cbc-left">
                <img src={course?.src} alt="Python" />
              </div>
              <div className="alac-pack-new-cbc-right">
                <div className="alac-pack-new-cbcr-top">
                  <div className="alac-pack-new-cbcrt-left">
                    <div className="alac-pack-new-card-heading">
                      {course?.title}
                    </div>
                    <div className="alac-pack-new-card-duration">
                      <img src={timer} alt="Timer" />
                      <p>Duration: {course?.duration}</p>
                    </div>
                  </div>
                  <div className="alac-pack-new-cbcrt-right">{ind + 1}</div>
                </div>
                <div
                  className="alac-pack-new-cbcr-bottom pointer"
                  onClick={() => {
                    document.body.classList.add("noScroll");
                    setCourse(course?.url);
                    setModal(true);
                  }}
                >
                  <img src={eye} alt="Eye" />
                  <p>Curriculum</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Curriculum;
