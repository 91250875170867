import React from "react";
import "../../styles/hero.css";
import HeroImg from "../../assets/HeroImg.png";
import useCart from "../../../contexts/Cart";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Hero({ notPurchasedCourses, setShowLogin, price }) {
  const { setSelectedCourse, setSelectedCategory } = useCart();
  const navigate = useNavigate();

  return (
    <section className="alac-pack-section alac-pack-hero-section">
      <svg
        className="alac-pack-hero-design"
        xmlns="http://www.w3.org/2000/svg"
        width="571"
        height="738"
        viewBox="0 0 571 738"
        fill="none"
      >
        <g opacity="0.4">
          <path
            d="M904.091 681.616C819.673 715.203 730.31 733.956 641.105 736.804C551.9 739.652 464.599 726.538 384.187 698.212C303.775 669.886 231.827 626.903 172.451 571.716C113.074 516.529 67.4317 450.219 38.1299 376.573C8.82808 302.926 -3.55953 223.386 1.67438 142.492C6.90829 61.5981 29.6612 -19.0644 68.6341 -94.8901C107.607 -170.716 162.036 -240.22 228.815 -299.434C295.593 -358.648 373.413 -406.412 457.831 -440L680.961 120.808L904.091 681.616Z"
            fill="url(#paint0_linear_181_166)"
            fillOpacity="0.3"
          />
          <path
            d="M898.341 667.164C816.099 699.886 729.039 718.155 642.133 720.93C555.226 723.704 470.175 710.928 391.836 683.332C313.496 655.736 243.402 613.86 185.555 560.096C127.709 506.331 83.2428 441.73 54.696 369.981C26.1493 298.233 14.0809 220.742 19.18 141.933C24.279 63.1238 46.4456 -15.46 84.4141 -89.3317C122.383 -163.203 175.41 -230.916 240.467 -288.604C305.525 -346.293 381.339 -392.826 463.581 -425.548L680.961 120.808L898.341 667.164Z"
            fill="url(#paint1_linear_181_166)"
            fillOpacity="0.3"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_181_166"
            x1="38.1299"
            y1="376.573"
            x2="293.129"
            y2="249.421"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#800080" />
            <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_181_166"
            x1="38.1299"
            y1="376.573"
            x2="293.129"
            y2="249.421"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#800080" />
            <stop offset="1" stopColor="#FF864C" stopOpacity="0.27" />
          </linearGradient>
        </defs>
      </svg>
      <div className="alac-pack-hero-top">
        <div className="alac-pack-ht-left">
          <h1 className="alac-pack-hero-heading alac-pack-fw-400 alac-pack-f-55 alac-pack-text-black">
            <span className="alac-pack-text-pink alac-pack-fw-700">
              Upgrade{" "}
            </span>
            to all access pack with our premium course!
          </h1>
          <div className="alac-pack-hero-ocb-middle-horizontal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
            >
              <g opacity="0.7">
                <path
                  d="M11.41 2.5547H20.1348C20.4254 2.55462 20.6481 2.55457 20.8429 2.57362C22.2276 2.70908 23.361 3.54162 23.8417 4.66308H7.70312C8.18374 3.54162 9.31715 2.70908 10.7019 2.57362C10.8966 2.55457 11.1194 2.55462 11.41 2.5547Z"
                  fill="#800080"
                  fillOpacity="0.87"
                />
                <path
                  d="M8.65983 5.95898C6.92161 5.95898 5.49634 7.00863 5.0207 8.40113C5.01079 8.43015 5.00127 8.45934 4.99219 8.48865C5.48986 8.33794 6.0078 8.23948 6.5321 8.17227C7.88253 7.99914 9.58913 7.99923 11.5716 7.99934H20.1864C22.1688 7.99923 23.8755 7.99914 25.2259 8.17227C25.7501 8.23948 26.2681 8.33794 26.7657 8.48865C26.7567 8.45934 26.7472 8.43015 26.7372 8.40113C26.2616 7.00863 24.8363 5.95898 23.0981 5.95898H8.65983Z"
                  fill="#800080"
                  fillOpacity="0.87"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19.9323 9.48145H11.6136C7.39525 9.48145 5.28607 9.48145 4.10131 10.7149C2.91654 11.9484 3.19529 13.8541 3.75281 17.6656L4.2815 21.2798C4.71871 24.2688 4.93731 25.7633 6.05876 26.6582C7.1802 27.5532 8.83422 27.5532 12.1423 27.5532H19.4036C22.7117 27.5532 24.3657 27.5532 25.4872 26.6582C26.6087 25.7633 26.8272 24.2688 27.2644 21.2798L27.7931 17.6656C28.3506 13.8542 28.6293 11.9484 27.4446 10.7149C26.2598 9.48145 24.1506 9.48145 19.9323 9.48145ZM18.9993 19.7962C19.6973 19.3636 19.6973 18.2434 18.9993 17.8108L14.785 15.1982C14.1066 14.7777 13.2731 15.325 13.2731 16.191V21.4161C13.2731 22.282 14.1066 22.8294 14.785 22.4089L18.9993 19.7962Z"
                  fill="#800080"
                  fillOpacity="0.87"
                />
              </g>
            </svg>
            <p className="alac-pack-fw-400">
              Upgrade all the <span className="alac-pack-fw-700">Courses</span>
            </p>
          </div>
          <div className="alac-pack-hero-ocb-middle-horizontal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="31"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
            >
              <g opacity="0.7">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.5225 13.2048H2.52344V21.9544C2.52344 22.949 2.91851 23.9028 3.62174 24.606C4.32498 25.3092 5.27877 25.7043 6.27329 25.7043H23.7726C24.7671 25.7043 25.7209 25.3092 26.4242 24.606C27.1274 23.9028 27.5225 22.949 27.5225 21.9544V13.2048ZM13.2555 18.2046L13.4055 18.0533C13.6334 17.8178 13.7596 17.5021 13.757 17.1744C13.7544 16.8466 13.6231 16.533 13.3915 16.3011C13.16 16.0692 12.8465 15.9375 12.5188 15.9344C12.1911 15.9314 11.8752 16.0571 11.6393 16.2847L9.35317 18.5708C9.11884 18.8052 8.98721 19.1231 8.98721 19.4545C8.98721 19.786 9.11884 20.1039 9.35317 20.3383L11.6393 22.6232C11.7546 22.7426 11.8926 22.8378 12.0451 22.9033C12.1976 22.9688 12.3616 23.0033 12.5275 23.0047C12.6935 23.0062 12.8581 22.9745 13.0117 22.9117C13.1653 22.8488 13.3049 22.756 13.4223 22.6387C13.5396 22.5213 13.6324 22.3817 13.6953 22.2281C13.7581 22.0745 13.7898 21.9099 13.7883 21.744C13.7869 21.578 13.7524 21.414 13.6869 21.2615C13.6214 21.109 13.5261 20.971 13.4068 20.8557L13.2555 20.7045H18.7728C19.1043 20.7045 19.4222 20.5728 19.6567 20.3384C19.8911 20.104 20.0228 19.7861 20.0228 19.4545C20.0228 19.123 19.8911 18.8051 19.6567 18.5707C19.4222 18.3363 19.1043 18.2046 18.7728 18.2046H13.2555ZM6.27329 5.70508C5.27877 5.70508 4.32498 6.10015 3.62174 6.80339C2.91851 7.50662 2.52344 8.46041 2.52344 9.45493V10.7049H27.5225V9.45493C27.5225 8.46041 27.1274 7.50662 26.4242 6.80339C25.7209 6.10015 24.7671 5.70508 23.7726 5.70508H6.27329Z"
                  fill="#800080"
                  fillOpacity="0.87"
                />
              </g>
            </svg>
            <p className="alac-pack-fw-400">Get lifetime refund validity</p>
          </div>
          <div className="alac-pack-htl-bottom">
            <div className="alac-pack-htl-bottom-left">
              <button
                className="alac-pack-enroll-now"
                onClick={() => {
                  setSelectedCategory(`upgrade-pro-all#${price}`);

                  setSelectedCourse((prev) => ({
                    ...prev,
                    upgrade: notPurchasedCourses,
                  }));

                  if (!Cookies.get("user_email")) {
                    setShowLogin(true);
                  } else {
                    if (notPurchasedCourses?.length > 0) {
                      navigate("/packpayment");
                    } else {
                      alert("Already purchased all courses");
                      window.location.href =
                        "https://upskill.tutedude.com/dashboard";
                    }
                  }
                }}
              >
               
                Upgrade now for ₹ {price.toLocaleString("en-IN")}
              </button>
            </div>
          </div>
        </div>
        <div className="alac-pack-ht-right">
          <img src={HeroImg} alt="Hero" />
        </div>
      </div>
    </section>
  );
}

export default Hero;
