import { useNavigate } from "react-router-dom";
import useCart from "../../../contexts/Cart";
import Cookies from "js-cookie";
import upload from "../../assets/upload.svg";
import "../../styles/UpgradeStickyCard.css";

function UpgradeStickyCard({
  off,
  price,
  dis,
  notPurchasedCourses,
  setShowLogin,
}) {
  const { setSelectedCourse, setSelectedCategory } = useCart();
  const navigate = useNavigate();

  off = off.toString();
  off += "%";
  return (
    <div className="win-float-cont">
      <div className="upg-fs-layout-bottomde">
        <div className="upg-fs-upbt-left">
          <div className="upg-fs-upbt-off">{off} OFF</div>
          <div className="upg-fs-upbt-pri">
            <span>{price}</span>
            <span className="upg-fs-total">Total Price</span>
          </div>
          <div className="upg-fs-upbt-dis">{dis}</div>
        </div>
        <button
          className="upg-fs-hero-button upg-fs-upbt-right"
          onClick={() => {
            setSelectedCategory(`upgrade-pro-intern#${price}`);

            setSelectedCourse((prev) => ({
              ...prev,
              upgrade: notPurchasedCourses,
            }));

            if (!Cookies.get("user_email")) {
              setShowLogin(true);
            } else {
              if (notPurchasedCourses?.length > 0) {
                navigate("/packpayment");
              } else {
                alert("Already purchased all courses");
window.location.href = "https://upskill.tutedude.com/dashboard";
              }
            }
          }}
        >
          <img src={upload} alt="upload" />
          <span>Upgrade to Pro Pack</span>
        </button>
        <button className="upg-fs-laybom-upgrade">Upgrade Now</button>
      </div>
    </div>
  );
}

export default UpgradeStickyCard;
