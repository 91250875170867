import { useEffect, useRef, useState } from "react";
import BrowseCourses from "../Components/HomePage/Components/BrowseCourses";
import Hero from "../Components/HomePage/Components/Hero";
import HowDifferent from "../Components/HomePage/Components/HowDifferent";
import Internship from "../Components/HomePage/Components/Internship";
import IntroFaq from "../Components/HomePage/Components/IntroFaq";
import IntroFooter from "../Components/HomePage/Components/IntroFooter";
import Whyus from "../Components/HomePage/Components/Whyus";

import SignUp from "../Components/SignUp";
import Login from "../Components/Login";
import WhyRefund from "../Components/WhyRefund";
import OtherCourses from "../Components/OtherCourses";
import Experiences from "../Components/Experiences";
import ProPackCards from "../Components/HomePage/Components/ProPackCards";
import { useLocation } from "react-router-dom";
import ForgotPass from "../Components/ForgotPass";
import Refund from "../Components/Refund";
import VideoCarousel from "../Components/VideoCarousel";
import { Helmet } from "react-helmet";
import { DiwaliStrip } from "../Components/DiwaliStrip/DiwaliStrip";
import DiwaliHowScholarship from "../Components/StickyCards/DiwaliHowRefund/DiwaliHowScholarship";

const Main = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [showFloatSelected, setShowFloatSelected] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [showSign, setShowSign] = useState(false);
  const customSlideToExploreRef = useRef(null);
  const { hash } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const handleScroll = () => {
      if (customSlideToExploreRef.current) {
        const customSlideToExploreRect =
          customSlideToExploreRef.current.getBoundingClientRect();
        const scrollPosition = window.scrollY || window.pageYOffset;
        if (scrollPosition >= customSlideToExploreRect.top) {
          setShowFloatSelected(true);
        } else {
          setShowFloatSelected(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    try {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [hash]);
  const breadcrumbJsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Tutedude Private Limited",
    alternateName: "Tutedude",
    url: "https://tutedude.com/",
    logo: "https://tutedude.com/static/media/logo.c6c76fa885bb4dc0e0dff48015f57887.svg",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917988800474",
      contactType: "customer service",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.linkedin.com/company/tutedudeofficial/",
      "https://instagram.com/tutedudeofficial",
      "https://www.facebook.com/tutedude.officials/",
      "https://www.youtube.com/channel/UC2NQ2Ovazltz1-PYLmT7jxQ?view_as=subscriber",
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbJsonLd)}
        </script>
        <link rel="canonical" href="https://tutedude.com/" data-react-helmet="true"/>
      <meta property="og:url" content={`${window.location.href}`} />
  </Helmet>
      {showForgot && <ForgotPass setShowForgot={setShowForgot} />}
      {showSign && (
        <SignUp setShowSign={setShowSign} setShowLogin={setShowLogin} />
      )}
      {showLogin && (
        <Login
          setShowLogin={setShowLogin}
          setShowForgot={setShowForgot}
          setShowSign={setShowSign}
        />
      )}
      <Hero setShowLogin={setShowLogin} setShowSign={setShowSign} />
      {showFloatSelected && <DiwaliStrip />}
      <div ref={customSlideToExploreRef}>
        <BrowseCourses />
      </div>

      <ProPackCards />
      <OtherCourses hideHeader={true} />
      <Internship />
      {/* <HowRefund cname={"Any"} /> */}
      {width <= 500 && <DiwaliHowScholarship />}

      {width > 500 && <Refund price="699" months="3" />}
      <WhyRefund />
      <Whyus />
      <HowDifferent />

      <VideoCarousel />
      <Experiences />
      <IntroFaq />
      <IntroFooter />
    </div>
  );
};

export default Main;
