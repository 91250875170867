import React from "react";
import "./Styles/WhyRefund.css";

const WhyRefund = () => {
  return (
    <div className="why-cont" id="why-refund">
      <div className="why-upper-cont">
        <div className="yellow"></div>
        <div className="green"></div>
        <div className="why-head">Why refund?</div>
        <div className="why-text">
          A fee ensures commitment and seriousness in learning, as free things
          are often undervalued.
        </div>
        <div className="why-video-cont">
          <iframe
            className="rm-vid"
            src="https://www.youtube.com/embed/hQKl0wLRE18?si=9FSN6fiKT3Q2WHni"
            title="YouTube video player"
            frameBorder="0"
            width="100%"
            height="100%"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default WhyRefund;
